import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import type { MenuSelectEvent } from "@progress/kendo-react-layout";
import { uniq } from "es-toolkit";
import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SUPPLIER_INVOICES_GRID_COLUMNS } from "../../../../../common/models/src/lib/constants/grid-column.constants";
import { DEFAULT_TAKE_SIZE } from "../../../../../common/models/src/lib/constants/grid.constants";
import { SUPPLIER_INVOICES } from "../../../../../common/models/src/lib/constants/invoice.constants";
import { JOBS_PAGE_SIZE } from "../../../../../common/models/src/lib/constants/job.constants";
import {
	LEGS_PAGE_SIZE,
	LEG_STATUSES,
	LEG_STATUS_MAPPINGS,
} from "../../../../../common/models/src/lib/constants/leg.constants";
import { ABSENT } from "../../../../../common/models/src/lib/constants/messages.constants";
import { CelerumActions } from "../../../../../common/models/src/lib/enums/actions.enum";
import { FilterItemType } from "../../../../../common/models/src/lib/enums/filter-item-type.enum";
import { SupplierInvoiceType } from "../../../../../common/models/src/lib/enums/invoice.enum";
import { LegStatus } from "../../../../../common/models/src/lib/enums/leg.enum";
import {
	ModalSize,
	ModalType,
} from "../../../../../common/models/src/lib/enums/modal.enums";
import type { IBase } from "../../../../../common/models/src/lib/interfaces/base.interface";
import type {
	IDateFilter,
	IFilterItem,
} from "../../../../../common/models/src/lib/interfaces/filter.interface";
import type { ILegSummary } from "../../../../../common/models/src/lib/interfaces/leg.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumFilters } from "../../../../../common/ui/src/lib/components/celerum-filters/celerum-filters.component";
import { CelerumGridHeader } from "../../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { getCurrencySymbol } from "../../../../../common/utils/src/lib/helpers/currency.helpers";
import { renderLegTypeTitle } from "../../../../../common/utils/src/lib/helpers/leg.helpers";
import { buildFilterQueryString } from "../../../../../common/utils/src/lib/helpers/query.helpers";
import { useOldLocalStorage } from "../../../../../common/utils/src/lib/hooks/use-local-storage.hook";
import { fetchJobTypesAction } from "../../../../../jobs/data-access/src/lib/job-types.slice";
import { canGenerateFileFront } from "../../../../../jobs/feature/src/lib/helpers/job-option.helpers";
import {
	changeLegStatusAction,
	clearLegsAction,
	deleteLegAction,
	fetchLegSummaryAction,
	updateLegSupplierInvoiceNumberAction,
} from "../../../../../legs/data-access/src/lib/legs.slice";
import { fetchSubcontractorsAllAction } from "../../../../../subcontractors/data-access/src/lib/subcontractors.slice";
import {
	canDelete,
	canDuplicate,
	canPause,
	canResume,
} from "../helpers/leg-option.helpers";

interface LegFiltersState {
	subcontractorFilter: IFilterItem[];
	statusFilter: IFilterItem[];
	assignedToFilter: IFilterItem[];
	jobTypeFilter: IFilterItem[];
	legTypeFilter: IFilterItem[];
	collectionDateFilter: IDateFilter[];
	deliveryDateFilter: IDateFilter[];
	supplierInvoiceFilter: IFilterItem[];
}

const initialFilterState: LegFiltersState = {
	subcontractorFilter: [],
	statusFilter: [],
	assignedToFilter: [],
	jobTypeFilter: [],
	legTypeFilter: [],
	collectionDateFilter: [],
	deliveryDateFilter: [],
	supplierInvoiceFilter: [],
};

const sortByNames = (a: IBase, b: IBase) => a.name.localeCompare(b.name);

export const SupplierInvoicesFeature = () => {
	const navigate = useNavigate();

	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const dispatch = useAppDispatch();

	const {
		jobTypes,
		authentication: { users, availableLegTypes },
		legs: { summary, total, loading },
	} = useAppSelector((state) => ({
		jobTypes: state.jobTypes.data,
		authentication: state.authentication,
		legs: state.legs,
	}));

	const [legFilters, setLegFilters] = useOldLocalStorage<LegFiltersState>(
		"supplierInvoicesFilterState",
		initialFilterState,
	);

	const [sort, setSort] = useState<string>("");
	const [filters, setFilters] = useState<string>("");
	const [page, setPage] = useState<Page>({ skip: 0, take: LEGS_PAGE_SIZE });
	const [searchFilter, setSearchFilter] = useState<string>("");
	const [selectedLegId, setSelectedLegId] = useState<number | null>(null);
	const [selectedAction, setSelectedAction] = useState<CelerumActions | null>(
		null,
	);
	const [showActionModal, setShowActionModal] = useState<boolean>(false);
	const [selectedLegIds, setSelectedLegIds] = useState<Set<number>>(
		new Set<number>(),
	);
	const [supplierInvoiceNumber, setSupplierInvoiceNumber] =
		useState<string>("");
	const [supplierInvoiceDate, setSupplierInvoiceDate] = useState<Date>(
		new Date(),
	);

	const [isCelerumModalOpen, setIsCelerumModalOpen] = useState<boolean>(false);
	const subcontractorsDto = useAppSelector(
		(state) => state.subcontractors.data,
	);
	const subcontractors = useMemo(
		() => [...subcontractorsDto].sort(sortByNames),
		[subcontractorsDto],
	);

	const renderedLegSummary = useMemo(() => {
		return summary.map((leg: ILegSummary) => {
			const collectionDate =
				leg.collectionDate && new Date(leg.collectionDate).toLocaleString();
			const deliveryDate =
				leg.deliveryDate && new Date(leg.deliveryDate).toLocaleString();
			const cost = `${getCurrencySymbol(leg.currencyCode)} ${(
				Math.round(leg.cost * 100) / 100
			).toFixed(2)}`;

			return {
				...leg,
				collectionDate,
				deliveryDate,
				cost,
				costNumber: leg.cost,
				uniqueId: leg.uniqueId,
				parentId: leg.jobId || leg.loadId,
				legId: leg.id,
				type: renderLegTypeTitle(leg),
				supplierInvoice:
					leg.supplierInvoice !== undefined
						? (SUPPLIER_INVOICES[leg.supplierInvoice]?.name ?? ABSENT)
						: ABSENT,
				goodNames: leg.goods?.map((x) => x.name).join(", ") ?? "",
				supplierInvoiceDateStr: leg.supplierInvoiceDate
					? new Date(leg.supplierInvoiceDate).toLocaleString()
					: "",
			};
		});
	}, [summary]);
	const selectedLegs = useMemo(
		() => renderedLegSummary.filter((x) => selectedLegIds.has(x.id)),
		[renderedLegSummary, selectedLegIds],
	);

	const subcontratorNames = useMemo(() => {
		const subContractorIds = new Set<number>(
			selectedLegs
				.map((x) => x.subcontractorId)
				.filter((x) => x != null) as number[],
		);
		const _subcontractors = subcontractors.filter((x) =>
			subContractorIds.has(x.id),
		);
		const names = uniq(_subcontractors.map((x) => x.name).filter((x) => x));
		return names.join(", ");
	}, [subcontractors, selectedLegs]);

	const totalPrice = useMemo(
		() => selectedLegs.reduce((acc, leg) => acc + (leg.costNumber ?? 0), 0),
		[selectedLegs],
	);

	const renderedJobTypesList = useMemo(
		() =>
			jobTypes.map((jobType) => {
				return {
					id: jobType.id,
					name: jobType.name,
				};
			}),
		[jobTypes],
	) as IBase[];

	const renderedAssignedToList = useMemo(
		() =>
			users.map((user) => {
				return {
					id: user.id,
					name: `${user.firstName} ${user.lastName}`,
				};
			}),
		[users],
	) as IBase[];

	const handleMoreOptions = {
		canDelete,
		canPause,
		canDuplicate,
		canResume,
		canGenerateFileFront,
	};

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < summary.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (summary[i]?.id === -1) {
				if (loading) {
					return;
				}

				const page = Math.ceil(skip / LEGS_PAGE_SIZE) + 1;
				dispatchWithNotifications({
					action: fetchLegSummaryAction,
					payload: { page, pageSize: LEGS_PAGE_SIZE, sort, filters },
					errorMessage: "Could not fetch legs.",
				});
				break;
			}
		}
	};

	const requestSortedData = (sort: string) => {
		setSort(sort);
		/**
		 * Always clear existing data and start fetching again
		 * from page 1.
		 */
		dispatch(clearLegsAction());
		dispatchWithNotifications({
			action: fetchLegSummaryAction,
			payload: { page: 1, pageSize: LEGS_PAGE_SIZE, sort, filters },
			errorMessage: "Could not fetch legs.",
		});
	};

	const closeActionModal = () => {
		setShowActionModal(false);
		setSelectedLegId(null);
		setSelectedAction(null);
	};

	const handleStatusChange = (
		legId: number,
		status: number,
		showSuccess = true,
	) => {
		dispatchWithNotifications({
			action: changeLegStatusAction,
			payload: { legId, status },
			successMessage: showSuccess ? "Status successfully updated." : undefined,
			errorMessage: "Could not update status.",
		});
	};

	const handleMoreOptionsSelected = (event: MenuSelectEvent, id: number) => {
		const value = event.item.text as keyof typeof CelerumActions;

		if (!value) {
			return;
		}

		setSelectedLegId(id);

		setSelectedAction(CelerumActions[value]);
		setShowActionModal(true);
	};

	const clearFilters = () => {
		setLegFilters(initialFilterState);
	};

	useEffect(() => {
		const typesFilterLists: IFilterItem[][] = [
			[
				{
					id: "supplierInvoice",
					value: [SupplierInvoiceType.NoInvoice],
					neq: true,
				},
			],
		];

		legFilters.subcontractorFilter?.length &&
			typesFilterLists.push(legFilters.subcontractorFilter);
		legFilters.statusFilter?.length &&
			typesFilterLists.push(legFilters.statusFilter);
		legFilters.jobTypeFilter?.length &&
			typesFilterLists.push(legFilters.jobTypeFilter);
		legFilters.legTypeFilter?.length &&
			typesFilterLists.push(legFilters.legTypeFilter);
		legFilters.assignedToFilter?.length &&
			typesFilterLists.push(legFilters.assignedToFilter);
		legFilters.supplierInvoiceFilter?.length &&
			typesFilterLists.push(legFilters.supplierInvoiceFilter);

		const combinedDateFilters = [
			...legFilters.collectionDateFilter,
			...legFilters.deliveryDateFilter,
		];

		const filters = buildFilterQueryString(
			searchFilter,
			[
				"customerName",
				"collectionLocationName",
				"deliveryLocationName",
				"uniqueId",
				"driverName",
				"parentEntityTypeName",
				"subcontractorName",
				"truckName",
				"underwayLegName",
				"supplierInvoiceNumber",
			],
			combinedDateFilters,
			typesFilterLists,
		);

		setFilters(filters);

		if (searchFilter || combinedDateFilters.length || typesFilterLists.length) {
			dispatch(
				fetchLegSummaryAction({ page: 1, pageSize: JOBS_PAGE_SIZE, filters }),
			);
		} else {
			dispatch(fetchLegSummaryAction({ page: 1, pageSize: JOBS_PAGE_SIZE }));
		}

		setPage({ skip: 0, take: DEFAULT_TAKE_SIZE });
	}, [searchFilter, legFilters, dispatch]);

	useEffect(() => {
		if (loading) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading]);

	useEffect(() => {
		dispatch(fetchJobTypesAction());
		dispatch(fetchSubcontractorsAllAction());

		return () => {
			dispatch(clearLegsAction());
		};
	}, [dispatch]);

	const handleModalSubmit = (
		supplierInvoiceNumber: string,
		supplierInvoiceDate: Date,
	) => {
		const promises = selectedLegs.map((leg) =>
			dispatchWithNotifications({
				action: updateLegSupplierInvoiceNumberAction,
				payload: { legId: leg.id, supplierInvoiceNumber, supplierInvoiceDate },
				successMessage: `Leg with id ${leg.id} successfully updated.`,
				errorMessage: "Could not update leg.",
			}),
		);
		Promise.all(promises).then(() => {
			setIsCelerumModalOpen(false);
			setSupplierInvoiceNumber("");
			setSelectedLegIds(new Set<number>());
			dispatchWithNotifications({
				action: fetchLegSummaryAction,
				payload: { page: 1, pageSize: JOBS_PAGE_SIZE, filters },
				errorMessage: "Could not fetch legs.",
			});
		});
	};

	const handleOpenApproveModal = () => setIsCelerumModalOpen(true);

	return (
		<>
			<CelerumGridHeader
				title="Supplier Invoices"
				addButtonName="Approve"
				addButtonDisabled={!selectedLegIds.size}
				handleOpenAddModal={handleOpenApproveModal}
				numberOfItems={total}
				numberOfSelectedItems={selectedLegIds.size}
			>
				<CelerumFilters
					filters={{
						subcontractorFilter: {
							name: "Subcontractor",
							column: "subcontractorId",
							values: subcontractors,
							type: FilterItemType.DROPDOWN,
						},
						statusFilter: {
							name: "Status",
							column: "status",
							values: LEG_STATUSES,
							type: FilterItemType.DROPDOWN,
						},
						jobTypeFilter: {
							name: "Job Type",
							column: "jobTypeId",
							values: renderedJobTypesList,
							type: FilterItemType.DROPDOWN,
						},
						legTypeFilter: {
							name: "Leg Type",
							column: "type",
							values: availableLegTypes,
							type: FilterItemType.DROPDOWN,
						},
						supplierInvoiceFilter: {
							name: "Supplier Invoice",
							column: "supplierInvoice",
							values: SUPPLIER_INVOICES.filter(
								(x) => x.id !== SupplierInvoiceType.NoInvoice,
							),
							type: FilterItemType.DROPDOWN,
						},
						assignedToFilter: {
							name: "Assigned To",
							column: "assignedTo",
							values: renderedAssignedToList,
							type: FilterItemType.DROPDOWN,
						},
						collectionDateFilter: {
							name: "Collection Date",
							column: "collectionDate",
							type: FilterItemType.DATERANGE,
						},
						deliveryDateFilter: {
							name: "Delivery Date",
							column: "deliveryDate",
							type: FilterItemType.DATERANGE,
						},
					}}
					setFilters={setLegFilters}
					initialValues={{
						subcontractorFilter: legFilters.subcontractorFilter,
						statusFilter: legFilters.statusFilter,
						jobTypeFilter: legFilters.jobTypeFilter,
						legTypeFilter: legFilters.legTypeFilter,
						assignedToFilter: legFilters.assignedToFilter,
						collectionDateFilter: legFilters.collectionDateFilter,
						deliveryDateFilter: legFilters.deliveryDateFilter,
						supplierInvoiceFilter: legFilters.supplierInvoiceFilter,
					}}
					setSearchFilter={setSearchFilter}
					clearFilters={clearFilters}
				/>
			</CelerumGridHeader>
			<CelerumGrid
				data={renderedLegSummary}
				commandCellType={1}
				total={total}
				page={page}
				setPage={setPage}
				columns={SUPPLIER_INVOICES_GRID_COLUMNS}
				handleNavigate={(id) => {
					const itemWithParentId = renderedLegSummary.find(
						(leg) => leg.jobId === id || leg.loadId === id,
					);
					if (itemWithParentId?.jobId === id) {
						navigate(`/jobs/${id}`, {
							state: { from: window.location.pathname },
						});
					} else {
						navigate(`/loads/${id}`, {
							state: { from: window.location.pathname },
						});
					}
				}}
				handleMoreOptions={handleMoreOptions}
				handleStatusChange={handleStatusChange}
				handleActionSelected={handleMoreOptionsSelected}
				navigateItem="parentId"
				actionField="legId"
				loading={loading}
				requestDataIfNeeded={requestDataIfNeeded}
				requestSortedData={requestSortedData}
				statusMappings={LEG_STATUS_MAPPINGS}
				selectedItemsIds={selectedLegIds}
				setSelectedItemsIds={setSelectedLegIds}
			/>
			<CelerumConfirmModal
				title={
					selectedAction &&
					`Are you sure you want to ${CelerumActions[selectedAction]
						?.toString()
						.toLowerCase()} this leg?`
				}
				entityName="leg"
				entityProperty="with ID"
				entityValue={selectedLegId?.toString()}
				isOpen={showActionModal}
				subtitle={
					selectedAction === CelerumActions.Delete
						? "The leg cannot be recovered."
						: "This action can be reverted afterwards."
				}
				type={
					selectedAction === CelerumActions.Delete
						? ModalType.Delete
						: ModalType.Warning
				}
				handleSubmit={() => {
					switch (selectedAction) {
						case CelerumActions.Delete: {
							dispatchWithNotifications({
								action: deleteLegAction,
								payload: selectedLegId,
								successMessage: `Leg with id ${selectedLegId} successfully deleted.`,
								errorMessage: "Could not delete leg.",
							});
							break;
						}
						case CelerumActions.Cancel: {
							dispatchWithNotifications({
								action: changeLegStatusAction,
								payload: { legId: selectedLegId, status: LegStatus.Cancelled },
								successMessage: `Leg with id ${selectedLegId} successfully cancelled.`,
								errorMessage: "Could not cancel leg.",
							});
							break;
						}
						case CelerumActions.Resume: {
							dispatchWithNotifications({
								action: changeLegStatusAction,
								payload: { legId: selectedLegId, status: LegStatus.Underway },
								successMessage: `Leg with id ${selectedLegId} resumed.`,
								errorMessage: "Could not resume leg.",
							});
							break;
						}
					}
					closeActionModal();
				}}
				handleClose={closeActionModal}
			/>
			<CelerumConfirmModal
				type={ModalType.Warning}
				title="Do you want to approve the selected legs?"
				subtitle={
					<ApproveModalBody
						subcontratorNames={subcontratorNames}
						totalPrice={totalPrice}
						onSupplierInvoiceNumberChange={setSupplierInvoiceNumber}
						onSupplierInvoiceDateChange={setSupplierInvoiceDate}
					/>
				}
				size={ModalSize.Medium}
				isOpen={isCelerumModalOpen}
				handleClose={() => {
					setIsCelerumModalOpen(false);
					setSupplierInvoiceNumber("");
				}}
				submitButtonDisabled={supplierInvoiceNumber === ""}
				handleSubmit={() =>
					handleModalSubmit(supplierInvoiceNumber, supplierInvoiceDate)
				}
			/>
		</>
	);
};

const parseDate = (date: string) => {
	const [year, month, day] = date.split("-");
	if (!year || !month || !day) return new Date(0);
	return new Date(
		Number.parseInt(year),
		Number.parseInt(month) - 1,
		Number.parseInt(day),
	);
};

// date to yyyy-mm-dd
const dateToString = (date: Date) => {
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();
	return `${year}-${month < 10 ? `0${month}` : month}-${
		day < 10 ? `0${day}` : day
	}`;
};

type ApproveModalBodyProps = {
	subcontratorNames: string;
	totalPrice: number;
	onSupplierInvoiceNumberChange: (value: string) => void;
	onSupplierInvoiceDateChange: (value: Date) => void;
};
const ApproveModalBody = ({
	subcontratorNames,
	totalPrice,
	onSupplierInvoiceNumberChange,
	onSupplierInvoiceDateChange,
}: ApproveModalBodyProps) => (
	<div style={{ textAlign: "center" }}>
		<div>
			Subcontractors: <b>{subcontratorNames}</b>
		</div>
		<div>
			Total price: <b>{(Math.round(totalPrice * 100) / 100).toFixed(2)}</b>
		</div>
		<br />
		<div>
			<div>Supplier invoice number</div>
			<Input
				type="text"
				placeholder="Enter supplier invoice number"
				onChange={(e) => onSupplierInvoiceNumberChange(e.value)}
				defaultValue=""
				style={{ width: "66%" }}
			/>
			<br />
			<br />
			<div>Supplier invoice date</div>
			<Input
				type="date"
				placeholder="Enter supplier invoice date"
				onChange={(e) => onSupplierInvoiceDateChange(parseDate(e.value))}
				defaultValue={dateToString(new Date())}
				style={{ width: "66%" }}
			/>
		</div>
		<br />
	</div>
);
