import { SvgIcon } from "@progress/kendo-react-common";
import { useCallback, useMemo, useState } from "react";
import {
	addAttachmentsAction,
	deleteAttachmentAction,
	fetchAttachmentUriAction,
} from "../../../../../../attachments/data-access/src/lib/attachments.slice";
import { houseIcon } from "../../../../../../common/models/src/lib/constants/icon.constants";
import { AttachmentUsage } from "../../../../../../common/models/src/lib/enums/attachment.enum";
import {
	LegStatus,
	LegType,
} from "../../../../../../common/models/src/lib/enums/leg.enum";
import {
	ModalSize,
	ModalType,
} from "../../../../../../common/models/src/lib/enums/modal.enums";
import { ParentType } from "../../../../../../common/models/src/lib/enums/parent-type.enum";
import type { IEntityAttachmentDto } from "../../../../../../common/models/src/lib/interfaces/attachment.interface";
import type { IBase } from "../../../../../../common/models/src/lib/interfaces/base.interface";
import type { IDocument } from "../../../../../../common/models/src/lib/interfaces/document.interface";
import type { IOperationLeg } from "../../../../../../common/models/src/lib/interfaces/leg.interface";
import type { AnyLeg } from "../../../../../../common/models/src/lib/types/leg.type";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import {
	CelerumButton,
	CelerumViewDetailsButton,
} from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumConfirmModal } from "../../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumDocumentsForm } from "../../../../../../common/ui/src/lib/components/celerum-documents-form/celerum-documents-form.component";
import { CelerumModal } from "../../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { CelerumTag } from "../../../../../../common/ui/src/lib/components/celerum-tag/celerum-tag.component";
import {
	attachDocumentsToLegAction,
	deleteLegAttachmentAction,
	generateCollectionNoteAction,
	generateDeliveryTicketAction,
	generateSubcontractorOrderAction,
} from "../../../../../data-access/src/lib/legs.slice";
import { BlankInformation } from "./components/blank-information/blank-information.component";
import { ClearCustomsInformation } from "./components/clear-customs-information/clear-customs-information.component";
import { FerryInformation } from "./components/ferry-information/ferry-information.component";
import { OperationInformation } from "./components/operation-information/operation-information.component";
import { SelectGoodsModalContent } from "./components/selected-goods-modal-content/selected-goods-modal-content.component";
import { StorageInformation } from "./components/storage-information/storage-information.component";
import styles from "./leg-accordion-content.module.css";

interface LegAccordionContentProps {
	leg: AnyLeg;
	parentType: ParentType;
	isJobInvoiced?: boolean;
}

export const LegAccordionContent = ({
	leg,
	parentType,
	isJobInvoiced = false,
}: LegAccordionContentProps) => {
	const { goods, status } = leg;

	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { businessUnits, trucks } = useAppSelector((state) => ({
		businessUnits: state.authentication.businessUnits,
		trucks: state.trucks.data,
	}));

	const [showModal, setShowModal] = useState<{
		documents: boolean;
		collectionNote: boolean;
		deliveryTicket: boolean;
		subcontractorOrder: boolean;
		pod: boolean;
	}>({
		documents: false,
		collectionNote: false,
		deliveryTicket: false,
		subcontractorOrder: false,
		pod: false,
	});
	const [selectedGoods, setSelectedGoods] = useState<IBase | undefined>(
		undefined,
	);

	const { name: businessUnitName } =
		useMemo(
			() =>
				businessUnits?.find(
					(businessUnit) =>
						businessUnit.id ===
						Number.parseInt(
							(leg as IOperationLeg).transferBusinessUnitId as string,
						),
				),
			[leg, businessUnits],
		) ?? {};

	const { podDocuments, documents } = useMemo(() => {
		const documentsObject: {
			podDocuments: IDocument[];
			documents: IDocument[];
		} = {
			podDocuments: [],
			documents: [],
		};

		for (const doc of leg.documents) {
			doc.isPod
				? documentsObject.podDocuments.push(doc)
				: documentsObject.documents.push(doc);
		}

		return documentsObject;
	}, [leg.documents]);

	const handleAddAttachments = async (formState: IEntityAttachmentDto) => {
		let payload = { ...formState };

		if (showModal.pod) {
			payload = { ...formState, isPod: true };
		}

		try {
			const actionResult = await dispatchWithNotifications({
				action: addAttachmentsAction,
				payload,
				successMessage: "Attachments successfully uploaded.",
				errorMessage: "Could not upload attachments.",
			});

			if (addAttachmentsAction.fulfilled.match(actionResult)) {
				dispatch(
					attachDocumentsToLegAction({
						documents: actionResult.payload,
						legId: formState.entityId,
					}),
				);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleDownloadLegDocument = (attachmentId: number | string) => {
		dispatch(fetchAttachmentUriAction(attachmentId));
	};

	const handleDeleteDocument = async (attachmentId: number | string) => {
		try {
			const actionResult = await dispatchWithNotifications({
				action: deleteAttachmentAction,
				payload: attachmentId,
				successMessage: "Attachment successfully deleted.",
				errorMessage: "Could not delete attachment.",
			});
			if (deleteAttachmentAction.fulfilled.match(actionResult)) {
				dispatch(deleteLegAttachmentAction({ id: attachmentId }));
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleGenerateCollectionNote = async () => {
		const result = await dispatchWithNotifications({
			action: generateCollectionNoteAction,
			payload: { legId: leg.id, goodsId: selectedGoods?.id },
		});

		if (generateCollectionNoteAction.fulfilled.match(result)) {
			const data = result.payload;
			window.open(URL.createObjectURL(data));
		}
	};

	const handleGenerateDeliveryTicket = async () => {
		const result = await dispatchWithNotifications({
			action: generateDeliveryTicketAction,
			payload: { legId: leg.id, goodsId: selectedGoods?.id },
		});

		if (generateDeliveryTicketAction.fulfilled.match(result)) {
			const data = result.payload;
			window.open(URL.createObjectURL(data));
		}
	};

	const handleGenerateSubcontractorOrder = async () => {
		const result = await dispatchWithNotifications({
			action: generateSubcontractorOrderAction,
			payload: { legId: leg.id, goodsId: selectedGoods?.id },
		});

		if (generateSubcontractorOrderAction.fulfilled.match(result)) {
			const data = result.payload;
			window.open(URL.createObjectURL(data));
		}
	};

	const renderInformation = useCallback(() => {
		switch (leg.type) {
			case LegType.Collection:
			case LegType.Segment:
			case LegType.Delivery:
			case LegType.CollectDeliver:
			case LegType.ContainerPickUp:
			case LegType.ContainerDropOff:
			case LegType.ContainerGoodsCollection:
			case LegType.ContainerGoodsDelivery:
			case LegType.LoadAndLash:
			case LegType.Devan: {
				const truck = trucks.find((truck) => truck.id === leg.truck?.id);
				const renderedLeg = {
					...leg,
					truck: truck
						? {
								id: truck.id,
								name:
									truck.name +
									(truck.subcontractor ? ` (${truck.subcontractor.name})` : ""),
							}
						: leg.truck,
				};
				return <OperationInformation leg={renderedLeg} />;
			}
			case LegType.ClearCustoms:
				return <ClearCustomsInformation leg={leg} />;
			case LegType.Storage:
				return <StorageInformation leg={leg} />;
			case LegType.Ferry:
				return <FerryInformation leg={leg} />;
			case LegType.Blank:
				return <BlankInformation leg={leg} />;
			default:
				return null;
		}
	}, [leg, trucks]);

	const renderCollectionNoteButton = () => {
		if (
			leg.type === LegType.Collection ||
			leg.type === LegType.CollectDeliver ||
			leg.type === LegType.Segment
		) {
			return (
				<div className={styles.container}>
					<CelerumButton
						onClick={() =>
							setShowModal((prev) => ({ ...prev, collectionNote: true }))
						}
						title="Collection Note"
					/>
				</div>
			);
		}
	};

	const renderDeliveryTicketButton = () => {
		if (leg.type === LegType.Delivery || leg.type === LegType.CollectDeliver) {
			return (
				<div className={styles.container}>
					<CelerumButton
						onClick={() =>
							setShowModal((prev) => ({ ...prev, deliveryTicket: true }))
						}
						title="Delivery Ticket"
					/>
				</div>
			);
		}
	};

	const renderSubcontractorOrderButton = () => {
		if (leg.type === LegType.Delivery || leg.type === LegType.CollectDeliver) {
			return (
				<div className={styles.container}>
					<CelerumButton
						onClick={() =>
							setShowModal((prev) => ({ ...prev, subcontractorOrder: true }))
						}
						title="Subcontractor Order"
					/>
				</div>
			);
		}
	};

	const renderAttachPodButton = () => {
		if (leg.type === LegType.Delivery || leg.type === LegType.CollectDeliver) {
			return (
				<div className={styles.container}>
					<div className={styles.container}>
						<CelerumViewDetailsButton
							onClick={() => setShowModal((prev) => ({ ...prev, pod: true }))}
							title={
								!podDocuments?.length
									? "Attach POD"
									: `View POD Documents (${podDocuments.length ?? 0})`
							}
						/>
					</div>
				</div>
			);
		}
	};

	const closeModal = () =>
		setShowModal({
			documents: false,
			collectionNote: false,
			deliveryTicket: false,
			subcontractorOrder: false,
			pod: false,
		});

	return (
		<>
			<div className={styles.container}>
				<div className={styles.section}>{renderInformation()}</div>
				{parentType === ParentType.Job && (
					<div className={styles.section}>
						<div className={styles.goods}>
							{goods?.map((item: IBase, index: number) => (
								<CelerumTag
									key={`${item.id}-goods-${index}`}
									name={item.name}
								/>
							))}
						</div>
					</div>
				)}
				{(leg as IOperationLeg).transferBusinessUnitId && (
					<div className={styles.section}>
						<div className={styles.homeBusinessUnit}>
							<SvgIcon icon={houseIcon} />
							<span>Business Unit: {businessUnitName}</span>
						</div>
					</div>
				)}
				<div className={styles.section}>
					<div className={styles.wrapper} style={{ alignItems: "flex-end" }}>
						<div className={styles.container}>
							<span className={styles.notes__label}>Note:</span>
							<span className={styles.notes__value}>
								{leg.notes ? leg.notes : "No notes for this leg."}
							</span>
						</div>
						<div>
							<CelerumViewDetailsButton
								onClick={() => {
									setShowModal((prev) => ({ ...prev, documents: true }));
								}}
								title={`View Documents (${documents.length})`}
							/>
						</div>
					</div>
				</div>
				{parentType === ParentType.Job && (
					<div className={styles.section}>
						<div
							className={styles.wrapper}
							style={{ justifyContent: "flex-end", alignItems: "flex-end" }}
						>
							{renderAttachPodButton()}
							{renderCollectionNoteButton()}
							{renderDeliveryTicketButton()}
							{renderSubcontractorOrderButton()}
						</div>
					</div>
				)}
			</div>
			<CelerumModal
				title={
					showModal.documents
						? "View Documents"
						: showModal.pod
							? "View POD Documents"
							: "View Document"
				}
				width={ModalSize.Small}
				visible={showModal.documents || showModal.pod}
				toggleDialog={closeModal}
			>
				<CelerumDocumentsForm
					documents={
						leg.documents ? (showModal.pod ? podDocuments : documents) : []
					}
					isInteractive={
						!(businessUnitName || status === LegStatus.PartOfALoad) &&
						!isJobInvoiced
					}
					entityId={leg.id}
					onClose={closeModal}
					handleAddAttachments={handleAddAttachments}
					handleDeleteAttachment={handleDeleteDocument}
					handleDownloadAttachment={handleDownloadLegDocument}
					entityUsage={AttachmentUsage.Leg}
				/>
			</CelerumModal>
			<CelerumConfirmModal
				type={ModalType.Warning}
				title={
					showModal.collectionNote
						? "Generate Collection Note"
						: showModal.deliveryTicket
							? "Generate Delivery Ticket"
							: "Generate Subcontractor Order"
				}
				subtitle="The process generates a downloadable PDF file."
				size={ModalSize.Medium}
				isOpen={
					showModal.collectionNote ||
					showModal.deliveryTicket ||
					showModal.subcontractorOrder
				}
				submitButtonDisabled={!selectedGoods}
				handleClose={() => {
					closeModal();
					setSelectedGoods(undefined);
				}}
				handleSubmit={() => {
					const { collectionNote, deliveryTicket, subcontractorOrder } =
						showModal;
					collectionNote && handleGenerateCollectionNote();
					deliveryTicket && handleGenerateDeliveryTicket();
					subcontractorOrder && handleGenerateSubcontractorOrder();
				}}
			>
				<SelectGoodsModalContent
					goods={leg.goods}
					setSelectedGoods={setSelectedGoods}
					selectedGoods={selectedGoods}
				/>
			</CelerumConfirmModal>
		</>
	);
};
