import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import type { SelectionRange } from "@progress/kendo-react-dateinputs";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useQuery } from "@tanstack/react-query";
import { sumBy, uniqBy } from "es-toolkit";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation, useTitle } from "react-use";
import { TypedDropDownButton } from "../TypedDropDownButton";
import type { LegActions } from "../api/JobApi";
import { useAuditDialog } from "../dialogs/useAuditDialog";
import { GenericDataGrid } from "../grids/GenericDataGrid";
import {
	type GenericGridProps,
	type LegStatusType,
	type NewLegType,
	TableNameType,
	type TypedGridColumnProps,
	jobApi,
	legStatusTypeNames,
	legTypeNames,
	supplierInvoiceTypeNames,
	toCurrency,
	toDateString,
	toDatetimeString,
	toasted,
	uncheckGridSelection,
} from "../helpers";
import {
	toCell,
	useDialog,
	useGenericDateRangePicker,
	useGenericLegStatusFilter,
	useGoodsSelector,
} from "../helpersReact";

type Leg = {
	id: number;
	actions: LegActions;
	jobId: number;
	loadId: number;
	number: string;
	jobType: string;
	type: NewLegType;
	typeName: string;
	customerName: string;
	assignedToName: string;
	collectionLocationName: string;
	collectionDate: Date | undefined;
	deliveryLocationName: string;
	deliveryDate: Date | undefined;
	driverName: string;
	supplierInvoiceNumber: string;
	supplierInvoiceType: string;
	supplierInvoiceDate: Date | undefined;
	supplierInvoiceDateString: string;
	status: number;
	collectionDateString: string;
	deliveryDateString: string;
	statusString: string;
	currencyCode: string | undefined;
	cost: number | undefined;
	subcontractorName: string;
};

const defaultColumns: TypedGridColumnProps<Leg>[] = [
	{ field: "id", title: "ID" },
	{ field: "number", title: "Number" },
	{ field: "jobType", title: "Job Type" },
	{ field: "typeName", title: "Type" },
	{ field: "customerName", title: "Customer" },
	{ field: "assignedToName", title: "Assigned To" },
	{ field: "collectionLocationName", title: "Collection Location" },
	{
		field: "collectionDate",
		title: "Collection Date",
		cell: ({ dataItem }) => toCell(dataItem.collectionDateString),
	},
	{ field: "deliveryLocationName", title: "Delivery Location" },
	{
		field: "deliveryDate",
		title: "Delivery Date",
		cell: ({ dataItem }) => toCell(dataItem.deliveryDateString),
	},
	{
		field: "cost",
		title: "Cost",
		filter: "numeric",
		cell: ({ dataItem }) =>
			toCell(
				dataItem.cost ? toCurrency(dataItem.cost, dataItem.currencyCode) : "",
			),
	},
	{ field: "currencyCode", title: "Currency", hidden: true },
	{ field: "driverName", title: "Driver" },
	{ field: "statusString", title: "Status" },
	{ field: "subcontractorName", title: "Subcontractor" },
	{ field: "supplierInvoiceType", title: "Supplier Invoice Type" },
	{ field: "supplierInvoiceNumber", title: "Supplier Invoice Number" },
	{
		field: "supplierInvoiceDate",
		title: "Supplier Invoice Date",
		cell: ({ dataItem }) => toCell(dataItem.supplierInvoiceDateString),
	},
];

const SupplierInvoicesGrid = (props: GenericGridProps<Leg>) => (
	<GenericDataGrid {...props} defaultColumns={defaultColumns} name="Legs" />
);

const useFetchData = (rangeValues: SelectionRange, statusValues: number[]) => {
	const _legs = useQuery({
		queryKey: [
			"jobApi.leg.legV2List",
			rangeValues.start,
			rangeValues.end,
			statusValues,
		],
		queryFn: () =>
			jobApi.leg
				.legV2List({
					DateFrom: rangeValues.start?.toISOString(),
					DateTo: rangeValues.end?.toISOString(),
					StatusIds: statusValues,
				})
				.then((x) => x.data),
		initialData: [],
		refetchInterval: 1000 * 30, // 30 seconds
	});
	const _jobTypes = useQuery({
		queryKey: ["jobApi.jobType.jobTypeList"],
		queryFn: () => jobApi.jobType.jobTypeList({}).then((x) => x.data.data),
		initialData: [],
	});
	const legs = useMemo(
		() =>
			_legs.data.map((item) => {
				const collectionDate = item.collectionDate
					? new Date(item.collectionDate)
					: undefined;
				const deliveryDate = item.deliveryDate
					? new Date(item.deliveryDate ?? "")
					: undefined;
				const supplierInvoiceDate = item.supplierInvoiceDate
					? new Date(item.supplierInvoiceDate)
					: undefined;
				const status = item.status ?? 0;
				const leg: Leg = {
					actions: item.actions as LegActions,
					assignedToName: item.assignedToName ?? "",
					collectionDate,
					collectionDateString: toDatetimeString(collectionDate),
					collectionLocationName: item.collectionLocationName ?? "",
					cost: item.cost ?? undefined,
					currencyCode: item.currencyCode ?? undefined,
					customerName: item.customerName ?? "",
					deliveryDate,
					deliveryDateString: toDatetimeString(deliveryDate),
					deliveryLocationName: item.deliveryLocationName ?? "",
					driverName: item.driverName ?? "",
					id: item.id ?? 0,
					jobId: item.jobId ?? 0,
					loadId: item.loadId ?? 0,

					jobType:
						_jobTypes.data.find((x) => x.id === item.jobTypeId)?.name ?? "",
					number: item.uniqueId ?? "",
					status,
					statusString:
						legStatusTypeNames[status as keyof typeof legStatusTypeNames] ?? "",
					subcontractorName: item.subcontractorName ?? "",
					supplierInvoiceDate,
					supplierInvoiceDateString: toDateString(supplierInvoiceDate),
					supplierInvoiceNumber: item.supplierInvoiceNumber ?? "",
					supplierInvoiceType:
						supplierInvoiceTypeNames[item.supplierInvoice ?? 0] ?? "",
					type: item.type ?? 0,
					typeName: legTypeNames[item.type ?? 0] ?? "",
				};
				return leg;
			}),
		[_jobTypes.data, _legs.data],
	);
	return { legs, retry: _legs.refetch, loading: _legs.isLoading };
};

const useExtraFilters = () => {
	const [dateRangeEl, rangeValues] =
		useGenericDateRangePicker("SupplierInvoices");
	const [filterStatusEl, statusValues] =
		useGenericLegStatusFilter("SupplierInvoices");
	return [
		<Fragment key="extraFilters">
			{filterStatusEl}
			{dateRangeEl}
		</Fragment>,
		rangeValues,
		statusValues,
	] as const;
};

type LegsGridAction = "view" | keyof LegActions;
type StatusItem = {
	text?: string;
	action: LegsGridAction;
	status?: LegStatusType;
	color?: string;
	data: Leg;
};
const Status = ({
	statusString,
	color,
}: { statusString: string; color?: string }) => (
	<span>
		<Icon name="circle" style={{ color }} />
		{statusString}
	</span>
);
const StatusItemRender = ({ item }: { item: StatusItem }) => (
	<Status statusString={item.text ?? ""} color={item.color ?? ""} />
);
const useExtraColumns = (handleAction: (item: StatusItem) => void) => {
	return useMemo(() => {
		const _columns: TypedGridColumnProps<Leg>[] = [
			{
				title: "Actions",
				cell: ({ dataItem }) => {
					const items: StatusItem[] = [];
					if (dataItem.actions.allowDelete) {
						items.push({
							text: "Delete",
							action: "allowDelete",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowGenerateCollectionNote) {
						items.push({
							text: "Generate Collection Note",
							action: "allowGenerateCollectionNote",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowGenerateDeliveryTicket) {
						items.push({
							text: "Generate Delivery Ticket",
							action: "allowGenerateDeliveryTicket",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowGenerateSubcontractorOrder) {
						items.push({
							text: "Generate Subcontractor Order",
							action: "allowGenerateSubcontractorOrder",
							data: dataItem,
						});
					}
					if (dataItem.actions.allowAudit) {
						items.push({
							text: "Audit",
							action: "allowAudit",
							data: dataItem,
						});
					}

					return (
						<td>
							<Button
								size="small"
								icon="eye"
								onClick={() => handleAction({ action: "view", data: dataItem })}
							/>
							<TypedDropDownButton
								size="small"
								icon="more-vertical"
								items={items}
								itemRender={StatusItemRender}
								onItemClick={(x) => handleAction(x.item)}
								popupSettings={{ animate: false }}
							/>
						</td>
					);
				},
				field: "actions",
				width: "70px",
			},
		];
		return _columns;
	}, [handleAction]);
};
const useHandleAction = (
	retry: () => void,
	toggleSelectGoodsModal: (
		legId: number,
		callback: (goodsId: number) => Promise<void>,
	) => void,
	showAuditFor: (id: string | number) => void,
) => {
	const navigate = useNavigate();
	const location = useLocation();
	return useCallback(
		(item: StatusItem): void => {
			const itemStatus = item.status;
			switch (item.action) {
				case "view":
					if (item.data.jobId) {
						navigate(`/jobs/${item.data.jobId}`, {
							state: { from: location.pathname },
						});
					} else if (item.data.loadId) {
						navigate(`/loads/${item.data.loadId}`, {
							state: { from: location.pathname },
						});
					}
					break;
				case "allowChangeStatus":
					if (!itemStatus) {
						console.error("Status not found");
						return;
					}
					toasted(
						jobApi.leg
							.legChangeStatusCreate({
								legId: item.data.id,
								status: itemStatus,
							})
							.then(retry),
						"Status changed successfully",
					);
					break;
				case "allowDelete":
					toasted(jobApi.leg.deleteLeg(item.data.id).then(retry), "Deleted");
					break;
				case "allowEdit":
					// purposefully not implemented
					throw new Error("Not implemented");
				case "allowGenerateDeliveryTicket":
					// purposefully not used
					toggleSelectGoodsModal(item.data.id, async (goodsId) => {
						toasted(
							jobApi.leg
								.legReportDeliveryTicketDetail(item.data.id, goodsId, {
									format: "blob",
								})
								.then((x) => window.open(URL.createObjectURL(x.data), "_blank"))
								.finally(retry),
							"Generating Delivery Ticket",
						);
					});
					break;
				case "allowGenerateCollectionNote":
					// purposefully not used
					toggleSelectGoodsModal(item.data.id, async (goodsId) => {
						toasted(
							jobApi.leg
								.legReportCollectionNoteDetail(item.data.id, goodsId, {
									format: "blob",
								})
								.then((x) => window.open(URL.createObjectURL(x.data), "_blank"))
								.finally(retry),
							"Generating Collection Note",
						);
					});
					break;
				case "allowGenerateSubcontractorOrder":
					// purposefully not used
					toasted(
						jobApi.leg
							.legReportSubcontractorOrderDetail(item.data.id, {
								format: "blob",
							})
							.then((x) => window.open(URL.createObjectURL(x.data), "_blank"))
							.finally(retry),
						"Generating Subcontractor Order",
					);
					break;
				case "allowAudit":
					showAuditFor(item.data.id);
					break;
				default:
					// biome-ignore lint/correctness/noVoidTypeReturn: To make sure we handle all cases
					return item.action;
			}
		},
		[location.pathname, navigate, toggleSelectGoodsModal, showAuditFor, retry],
	);
};

type InvoicingProceed = {
	customerName: string;
	jobNumbers: string[];
	invoiceTotal: string;
};

const useSelected = (invoicing: Leg[]) => {
	const [selected, setSelected] = useState<Set<number>>(new Set());
	const selectedInvoices = useMemo(
		() => invoicing.filter((x) => selected.has(x.id)),
		[invoicing, selected],
	);
	const selectedCustomerCurrencyCode = useMemo(
		() =>
			selectedInvoices.reduce(
				(acc, x) => (acc ? acc : x.currencyCode),
				"" as string | undefined,
			),
		[selectedInvoices],
	);
	const selectedTotalGrossSum = useMemo(
		() => selectedInvoices.reduce((acc, x) => acc + (x.cost ?? 0), 0),
		[selectedInvoices],
	);
	const buttonText = useMemo(
		() =>
			selected.size
				? `Approve (${selected.size} for ${toCurrency(
						selectedTotalGrossSum,
						selectedCustomerCurrencyCode,
					)})`
				: "Approve",
		[selected.size, selectedTotalGrossSum, selectedCustomerCurrencyCode],
	);
	return { setSelected, buttonText, selectedInvoices };
};

const useExtraButtons = (
	selectedInvoices: Leg[],
	buttonText: string,
	retry: () => void,
) => {
	const [date, setDate] = useState(new Date());
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const close = useCallback(() => {
		setDate(new Date());
		setInvoiceNumber("");
		showDialog(false);
	}, []);
	const handleApprove = useCallback(async () => {
		const selectedCheckboxIds = selectedInvoices.map((x) => x.id);
		await toasted(
			Promise.all(
				selectedCheckboxIds.map((id) =>
					jobApi.leg.legSupplierInvoiceNumberUpdate(id, {
						id: id,
						supplierInvoiceDate: date.toISOString(),
						supplierInvoiceNumber: invoiceNumber,
					}),
				),
			),
			"Approving invoices",
		)
			.then(retry)
			.then(() => close())
			.then(() => uncheckGridSelection());
	}, [close, date, invoiceNumber, retry, selectedInvoices]);

	const invoicesProceed: InvoicingProceed[] = useMemo(() => {
		const invoicesProceed: InvoicingProceed[] = [];
		for (const invoiceByUniqueCustomer of uniqBy(
			selectedInvoices,
			(x) => x.id,
		)) {
			const invoices = selectedInvoices.filter(
				(x) => x.id === invoiceByUniqueCustomer.id,
			);
			invoicesProceed.push({
				customerName: invoiceByUniqueCustomer.customerName,
				jobNumbers: invoices.map((x) => x.id.toString()),
				invoiceTotal: toCurrency(sumBy(invoices, (x) => x.cost ?? 0)),
			});
		}
		return invoicesProceed;
	}, [selectedInvoices]);

	const dialogContent = (
		<>
			<p>
				Are you sure you want to approve invoices for the following customers?
			</p>
			{
				<table style={{ width: "100%" }}>
					<thead>
						<tr>
							<th>Customer Name</th>
							<th>Job Numbers</th>
							<th>Invoice Total</th>
						</tr>
					</thead>
					<tbody>
						{invoicesProceed.map((x) => (
							<tr key={x.customerName}>
								<td>{x.customerName}</td>
								<td>
									{x.jobNumbers.map((jobNumber) => (
										<div key={jobNumber}>{jobNumber}</div>
									))}
								</td>
								<td>{x.invoiceTotal}</td>
							</tr>
						))}
					</tbody>
				</table>
			}
			<p>
				Please select the invoice date and provide an Invoice Number before
				approving the invoice:
			</p>
			<input
				type="date"
				value={date.toISOString().slice(0, 10)}
				onChange={(e) => setDate(new Date(e.target.value))}
			/>

			<input
				type="text"
				value={invoiceNumber}
				onChange={(e) => setInvoiceNumber(e.target.value)}
			/>

			<DialogActionsBar>
				<Button onClick={() => showDialog(false)} themeColor="secondary">
					No
				</Button>
				<Button onClick={handleApprove} themeColor="primary">
					Yes
				</Button>
			</DialogActionsBar>
		</>
	);
	const [showDialog, dialog] = useDialog(
		dialogContent,
		"Do you want to proceed?",
	);
	const buttons = (
		<Button
			icon="plus"
			themeColor="primary"
			onClick={() => showDialog()}
			disabled={!selectedInvoices.length}
		>
			{buttonText}
		</Button>
	);
	return [buttons, dialog] as const;
};

export const SupplierInvoicesPage2 = () => {
	useTitle("Supplier Invoices");
	const [extraFilters, rangeValues, statusValues] = useExtraFilters();
	const [showAuditFor, auditDialog] = useAuditDialog(TableNameType.Leg);
	const { legs, retry, loading } = useFetchData(rangeValues, statusValues);
	const [toggleSelectGoodsModal, selectGoodsModal] = useGoodsSelector();
	const { setSelected, buttonText, selectedInvoices } = useSelected(legs);
	const handleAction = useHandleAction(
		retry,
		toggleSelectGoodsModal,
		showAuditFor,
	);
	const extraColumns = useExtraColumns(handleAction);

	const [extraButtons, dialog] = useExtraButtons(
		selectedInvoices,
		buttonText,
		retry,
	);

	return (
		<>
			{auditDialog}
			{selectGoodsModal}
			{dialog}
			<SupplierInvoicesGrid
				data={legs}
				loading={loading}
				refresh={retry}
				extraColumns={extraColumns}
				extraFilters={extraFilters}
				extraButtons={extraButtons}
				onSelectionChange={setSelected}
				footer={{
					cost: (data) => (
						<td>{toCurrency(sumBy(data, (x) => x.cost ?? 0))}</td>
					),
				}}
			/>
		</>
	);
};
