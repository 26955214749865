import type { Page } from "@progress/kendo-react-dropdowns";
import type { GridPageChangeEvent } from "@progress/kendo-react-grid";
import type { MenuSelectEvent } from "@progress/kendo-react-layout";
import NProgress from "nprogress";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { INVOICING_CHECK_GRID_COLUMNS } from "../../../../../common/models/src/lib/constants/grid-column.constants";
import { DEFAULT_TAKE_SIZE } from "../../../../../common/models/src/lib/constants/grid.constants";
import { INVOICES_PAGE_SIZE } from "../../../../../common/models/src/lib/constants/invoice.constants";
import { JOB_STATUS_MAPPINGS } from "../../../../../common/models/src/lib/constants/job.constants";
import { NOT_AVAILABLE } from "../../../../../common/models/src/lib/constants/messages.constants";
import { CelerumActions } from "../../../../../common/models/src/lib/enums/actions.enum";
import { CommandCellType } from "../../../../../common/models/src/lib/enums/command-cell.enum";
import { FilterItemType } from "../../../../../common/models/src/lib/enums/filter-item-type.enum";
import { InvoiceTypeMethod } from "../../../../../common/models/src/lib/enums/invoice.enum";
import { JobStatus } from "../../../../../common/models/src/lib/enums/job.enum";
import {
	ModalSize,
	ModalType,
} from "../../../../../common/models/src/lib/enums/modal.enums";
import type { IBase } from "../../../../../common/models/src/lib/interfaces/base.interface";
import type {
	IDateFilter,
	IFilterItem,
} from "../../../../../common/models/src/lib/interfaces/filter.interface";
import type { IInvoiceableJob } from "../../../../../common/models/src/lib/interfaces/invoice.interface";
import type { IPartialUpdateJobRequest } from "../../../../../common/models/src/lib/interfaces/job.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumConfirmModal } from "../../../../../common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumFilters } from "../../../../../common/ui/src/lib/components/celerum-filters/celerum-filters.component";
import { CelerumGridHeader } from "../../../../../common/ui/src/lib/components/celerum-grid-header/celerum-grid-header.component";
import { CelerumGrid } from "../../../../../common/ui/src/lib/components/celerum-grid/celerum-grid.component";
import { CelerumLoader } from "../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import {
	getCurrencySymbol,
	getFormattedValue,
} from "../../../../../common/utils/src/lib/helpers/currency.helpers";
import { buildFilterQueryString } from "../../../../../common/utils/src/lib/helpers/query.helpers";
import { useOldLocalStorage } from "../../../../../common/utils/src/lib/hooks/use-local-storage.hook";
import { partialUpdateJobAction } from "../../../../../jobs/data-access/src/lib/jobs.slice";
import {
	clearInvoiceableJobsAction,
	fetchInvoiceableJobsAction,
	markInvoiceableJobsAsCheckedAction,
} from "../../../../data-access/src/lib/invoices.slice";
import { GeneratedInvoiceModalContent } from "../components/generated-invoice-modal-content/generated-invoice-modal-content.component";
import {
	canPreviewInvoice,
	canSendToOperations,
} from "../helpers/invoice-option.helpers";
import { useInvoiceableJobActionSelected } from "../hooks/use-invoiceable-job-action-selected.hook";
import styles from "./invoicing-check-feature.module.css";

interface InvoicesFilterState {
	statusFilter: IFilterItem[];
	assignedToFilter: IFilterItem[];
	startDateFilter: IDateFilter[];
	endDateFilter: IDateFilter[];
}

type RenderedInvoice = Omit<IInvoiceableJob, "grossSum" | "cost"> & {
	canBeChecked: boolean;
	invoicePreference: string;
	vatRate: string;
	podStateString: string;
	grossSum: string;
	cost: string;
	grossSumNumber: number;
};

const initialFilterState: InvoicesFilterState = {
	statusFilter: [{ value: [JobStatus.READY_FOR_INVOICE], id: "status" }],
	assignedToFilter: [],
	startDateFilter: [],
	endDateFilter: [],
};

export const InvoicingCheckFeature = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const navigate = useNavigate();
	const handleSelectedAction = useInvoiceableJobActionSelected();

	const {
		invoices,
		total,
		generatedInvoices,
		loading,
		authentication: { users },
	} = useAppSelector((state) => ({
		invoices: state.invoices.data,
		total: state.invoices.total,
		loading: state.invoices.loading,
		authentication: state.authentication,
		generatedInvoices: state.invoices.generatedInvoices,
	}));

	const [page, setPage] = useState<Page>({ skip: 0, take: INVOICES_PAGE_SIZE });
	const [sort, setSort] = useState<string>("");
	const [filters, setFilters] = useState<string>("");
	const [searchFilter, setSearchFilter] = useState<string>("");
	const [selectedJobIds, setSelectedJobIds] = useState<Set<number>>(
		new Set<number>(),
	);
	const [selectedAction, setSelectedAction] = useState<CelerumActions | null>(
		null,
	);
	const [selectedInvoiceableJobId, setSelectedInvoiceableJobId] =
		useState<CelerumActions | null>(null);
	const [selectedInvoiceableJobStatus, setSelectedInvoiceableJobStatus] =
		useState<JobStatus | undefined>(undefined);
	const [openInvoicingCheckModal, setOpenInvoicingCheckModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
	const [showActionModal, setShowActionModal] = useState<boolean>(false);
	const [postingDate, setPostingDate] = useState<Date>(new Date());

	const [invoiceFilters, setInvoiceFilters] =
		useOldLocalStorage<InvoicesFilterState>(
			"invoicingCheckFilterState",
			initialFilterState,
		);

	const renderedInvoices: RenderedInvoice[] = useMemo(() => {
		return invoices.map((invoice) => {
			const canBeChecked = false;

			const startDate = new Date(invoice.startDate).toLocaleString();
			const endDate = new Date(invoice.endDate).toLocaleString();
			const podStateString = `${invoice.totalLegsWithPods}/${invoice.totalLegsNeedingPods}`;
			const cost = `${getCurrencySymbol(invoice.customerCurrencyCode)} ${(
				Math.round((invoice.cost ?? 0) * 100) / 100
			).toFixed(2)}`;
			const grossSum = `${getCurrencySymbol(invoice.customerCurrencyCode)}  ${(
				Math.round((invoice.grossSum ?? 0) * 100) / 100
			).toFixed(2)}`;

			return {
				...invoice,
				canBeChecked,
				startDate,
				endDate,
				cost,
				grossSum,
				grossSumNumber: invoice.grossSum,
				vatRate:
					invoice.vatRate && invoice.vatRate !== -1
						? `${getFormattedValue(invoice.vatRate as number)}%`
						: NOT_AVAILABLE,
				invoicePreference:
					InvoiceTypeMethod[invoice.customerInvoiceType] ?? NOT_AVAILABLE,
				podStateString,
			};
		});
	}, [invoices]);

	const renderedSelectedInvoices = useMemo(() => {
		const invoicesList = renderedInvoices.filter((invoice) => {
			return selectedJobIds?.has(invoice.id);
		});

		const resultMap: { [key: number]: RenderedInvoice } = {};

		for (const item of invoicesList) {
			const { customerId, uniqueId } = item;

			if (customerId) {
				if (!resultMap[customerId]) {
					resultMap[customerId] = {
						...item,
						uniqueIdList: [],
					};
				}

				if (resultMap[customerId] != null) {
					resultMap[customerId]?.uniqueIdList.push({
						uniqueId: uniqueId,
						cost: item.grossSumNumber,
						id: item.id,
					});
				}
			}
		}

		const resultArray = Object.values(resultMap);

		return resultArray as RenderedInvoice[];
	}, [renderedInvoices, selectedJobIds]);

	const renderedAssignedToList = useMemo(
		() =>
			users.map((user) => {
				return {
					id: user.id,
					name: `${user.firstName} ${user.lastName}`,
				};
			}),
		[users],
	) as IBase[];

	const isInvoiceCreationActionSuccessful = useMemo(() => {
		return generatedInvoices.every(
			(item) => !item.invoices.every((invoice) => invoice.isFailed),
		);
	}, [generatedInvoices]);

	const handleInvoiceFilters = useCallback(() => {
		const typesFilterLists = [];

		invoiceFilters.statusFilter.length &&
			typesFilterLists.push(invoiceFilters.statusFilter);
		invoiceFilters.assignedToFilter.length &&
			typesFilterLists.push(invoiceFilters.assignedToFilter);

		const combinedDateFilters = [
			...invoiceFilters.startDateFilter,
			...invoiceFilters.endDateFilter,
		];

		const filters = buildFilterQueryString(
			searchFilter,
			[
				"customerName",
				"uniqueId",
				"purchaseOrderNumber",
				"customerCurrencyCode",
			],
			combinedDateFilters,
			typesFilterLists,
		);

		setFilters(filters);

		const payload = {
			page: 1,
			pageSize: INVOICES_PAGE_SIZE,
			...(searchFilter || combinedDateFilters.length || typesFilterLists.length
				? { filters }
				: {}),
		};

		dispatchWithNotifications({
			action: fetchInvoiceableJobsAction,
			payload,
			errorMessage: "Could not fetch invoiceable jobs.",
		});

		setPage({ skip: 0, take: DEFAULT_TAKE_SIZE });
	}, [dispatchWithNotifications, invoiceFilters, searchFilter]);

	const handleInvoicingCheck = () => {
		dispatchWithNotifications({
			action: markInvoiceableJobsAsCheckedAction,
			payload: { ids: [...selectedJobIds], status: JobStatus.CHECKED },
			successMessage: "Jobs successfully checked.",
			errorMessage: "Could not update jobs.",
		}).then(() => {
			setSelectedJobIds(new Set<number>());
			handleInvoiceFilters();
		});
	};

	const clearFilters = () => {
		setInvoiceFilters(initialFilterState);
	};

	const requestDataIfNeeded = (event: GridPageChangeEvent) => {
		const { skip, take } = event.page;
		for (let i = skip; i < skip + take && i < invoices.length; i++) {
			/** if there is a row with ID -1, it means that we need to fetch more data. */
			if (invoices[i]?.id === -1) {
				if (loading.invoices) {
					return;
				}

				const page = Math.ceil(skip / INVOICES_PAGE_SIZE) + 1;

				dispatchWithNotifications({
					action: fetchInvoiceableJobsAction,
					payload: { page, pageSize: INVOICES_PAGE_SIZE, sort, filters },
					errorMessage: "Could not fetch invoiceable jobs.",
				});
				break;
			}
		}
	};

	const requestSortedData = (sort: string) => {
		setSort(sort);
		/**
		 * Always clear existing data and start fetching again
		 * from page 1.
		 */
		dispatch(clearInvoiceableJobsAction());
		dispatchWithNotifications({
			action: fetchInvoiceableJobsAction,
			payload: { page: 1, pageSize: INVOICES_PAGE_SIZE, sort, filters },
			errorMessage: "Could not fetch invoiceable jobs.",
		});
	};

	const closeModal = () => {
		setShowActionModal(false);
		setSelectedInvoiceableJobId(null);
		setSelectedAction(null);
	};

	const handleMoreOptions = {
		canSendToOperations,
		canPreviewInvoice,
	};

	const handleMoreOptionsSelected = (event: MenuSelectEvent, id: number) => {
		const value = event.item.text as keyof typeof CelerumActions;

		if (!value) return;

		const status = renderedInvoices.find(
			(invoice) => invoice.id === id,
		)?.status;

		setSelectedInvoiceableJobStatus(status);
		setSelectedInvoiceableJobId(id);
		setSelectedAction(CelerumActions[value]);
		setShowActionModal(true);
	};

	const handleUpdate = async (item: RenderedInvoice) => {
		const requestObject: IPartialUpdateJobRequest = {
			id: item.id,
			customerId: item.customerId,
			purchaseOrderNumber: item.purchaseOrderNumber ?? undefined,
			price: item.price,
		};
		await dispatchWithNotifications({
			action: partialUpdateJobAction,
			payload: requestObject,
			errorMessage: "Could not update invoiceable job.",
			successMessage: "Invoiceable job updated successfully.",
		});
		const { skip } = page;
		const pageIndex = Math.ceil(skip / INVOICES_PAGE_SIZE) + 1;
		dispatch(
			fetchInvoiceableJobsAction({
				page: pageIndex,
				pageSize: INVOICES_PAGE_SIZE,
				sort,
				filters,
			}),
		);
	};

	useEffect(() => {
		handleInvoiceFilters();
	}, [handleInvoiceFilters]);

	useEffect(() => {
		if (loading.invoices) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading.invoices]);

	return (
		<>
			<CelerumGridHeader
				title="Invoicing Check"
				numberOfItems={total}
				addButtonName="Mark as checked"
				addButtonDisabled={!renderedSelectedInvoices.length}
				handleOpenAddModal={() => setOpenInvoicingCheckModal(true)}
			>
				<CelerumFilters
					setFilters={setInvoiceFilters}
					setSearchFilter={setSearchFilter}
					clearFilters={clearFilters}
					initialValues={{
						statusFilter: invoiceFilters.statusFilter,
						assignedToFilter: invoiceFilters.assignedToFilter,
						startDateFilter: invoiceFilters.startDateFilter,
						endDateFilter: invoiceFilters.endDateFilter,
					}}
					filters={{
						assignedToFilter: {
							name: "Assigned To",
							column: "assignedTo",
							values: renderedAssignedToList,
							type: FilterItemType.DROPDOWN,
						},
						startDateFilter: {
							name: "Start Date",
							column: "startDate",
							type: FilterItemType.DATERANGE,
						},
						endDateFilter: {
							name: "End Date",
							column: "endDate",
							type: FilterItemType.DATERANGE,
						},
					}}
				/>
			</CelerumGridHeader>
			<CelerumGrid
				page={page}
				setPage={setPage}
				columns={INVOICING_CHECK_GRID_COLUMNS}
				data={renderedInvoices}
				total={total}
				requestDataIfNeeded={requestDataIfNeeded}
				requestSortedData={requestSortedData}
				handleMoreOptions={handleMoreOptions}
				handleActionSelected={handleMoreOptionsSelected}
				loading={loading.invoices}
				commandCellType={CommandCellType.NavigateViewMore}
				handleNavigate={(id) =>
					navigate(`${id}`, { state: { from: window.location.pathname } })
				}
				selectedItemsIds={selectedJobIds}
				setSelectedItemsIds={setSelectedJobIds}
				statusMappings={JOB_STATUS_MAPPINGS}
				handleUpdate={handleUpdate}
			/>
			<CelerumConfirmModal
				type={ModalType.Warning}
				title="Do you want to proceed?"
				subtitle="This action will set the status of the selected invoiceable jobs to 'Checked'."
				size={ModalSize.Medium}
				isOpen={openInvoicingCheckModal}
				handleClose={() => setOpenInvoicingCheckModal(false)}
				handleSubmit={handleInvoicingCheck}
			/>
			<CelerumModal
				width={ModalSize.Small}
				visible={loading.areInvoicesGenerated}
				height={325}
			>
				<div className={styles.loader}>
					<CelerumLoader visible />
				</div>
			</CelerumModal>
			<CelerumConfirmModal
				title={
					selectedAction &&
					`Are you sure you want to ${CelerumActions[selectedAction]
						?.toString()
						.toLowerCase()} this invoiceable job?`
				}
				itemName={`invoiceable job with id ${selectedInvoiceableJobId}`}
				isOpen={showActionModal}
				type={ModalType.Warning}
				handleSubmit={() => {
					handleSelectedAction(
						selectedAction,
						selectedInvoiceableJobId,
						selectedInvoiceableJobStatus,
					);
					closeModal();
				}}
				handleClose={closeModal}
			/>
			<CelerumConfirmModal
				type={
					isInvoiceCreationActionSuccessful
						? ModalType.Success
						: ModalType.Error
				}
				title={
					isInvoiceCreationActionSuccessful
						? "Invoices successfully generated"
						: "Some invoices not generated"
				}
				size={ModalSize.Medium}
				isOpen={showSuccessModal}
				handleClose={() => {
					setShowSuccessModal(false);
					setSelectedJobIds(new Set<number>());
				}}
				handleSubmit={handleInvoicingCheck}
				showSubmitButton={false}
				cancelButtonName="Cancel"
			>
				<GeneratedInvoiceModalContent
					invoices={generatedInvoices}
					setPostingDate={setPostingDate}
					postingDate={postingDate}
				/>
			</CelerumConfirmModal>
		</>
	);
};
