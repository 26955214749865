import { SvgIcon } from "@progress/kendo-react-common";
import { DatePicker, DateTimePicker } from "@progress/kendo-react-dateinputs";
import {
	ComboBox,
	type ComboBoxChangeEvent,
	DropDownList,
	MultiSelect,
} from "@progress/kendo-react-dropdowns";
import {
	type FieldRenderProps,
	FieldWrapper,
} from "@progress/kendo-react-form";
import {
	Input,
	type InputHandle,
	InputSuffix,
	RadioGroup,
	TextArea,
	type TextAreaHandle,
	TextBox,
} from "@progress/kendo-react-inputs";
import { Hint, Label, Error as LabelError } from "@progress/kendo-react-labels";
import {
	Upload,
	type UploadFileInfo,
	type UploadOnAddEvent,
} from "@progress/kendo-react-upload";
import cls from "classnames";
import { type ReactNode, useEffect, useRef, useState } from "react";
import { tooltipIcon } from "../../../../../models/src/lib/constants/icon.constants";
import type { IBase } from "../../../../../models/src/lib/interfaces/base.interface";
import styles from "./celerum-form-elements.module.css";

export const CelerumFormInput = ({
	validationMessage,
	required,
	touched,
	label,
	id,
	valid,
	disabled,
	hint,
	errorMessage,
	type,
	optional,
	focused,
	...others
}: FieldRenderProps) => {
	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";

	const inputRef = useRef<InputHandle>(null);

	useEffect(() => {
		if (inputRef.current && focused === "true") {
			inputRef.current.element?.focus();
		}
	}, [focused]);

	return (
		<FieldWrapper className={styles.inputContainer}>
			<Label
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				optional={optional}
				style={{
					color: disabled ? "#9D9DAB" : valid ? "#000000" : "",
				}}
			>
				{label} {required && <span style={{ color: "red" }}>*</span>}
			</Label>
			<div className={"k-form-field-wrap"}>
				<Input
					className={styles.input}
					valid={valid}
					type={type}
					id={id}
					disabled={disabled}
					ariaDescribedBy={`${hintId} ${errorId}`}
					ref={inputRef}
					{...others}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<LabelError id={errorId}>{validationMessage}</LabelError>
				)}
				{errorMessage && <LabelError>{errorMessage}</LabelError>}
			</div>
		</FieldWrapper>
	);
};

// biome-ignore lint/suspicious/noExplicitAny: can't be bothered
export const CelerumDisabledInput = ({ label, value }: any) => {
	return (
		<FieldWrapper className={styles.inputContainer}>
			<Label
				style={{
					color: "#9D9DAB",
				}}
				editorDisabled={true}
			>
				<div
					style={{
						marginRight: "0.3vw",
					}}
				>
					{label}
				</div>
				<SvgIcon icon={tooltipIcon} />
			</Label>
			<div className={"k-form-field-wrap"}>
				<Input className={styles.input} disabled={true} value={value} />
			</div>
		</FieldWrapper>
	);
};

export const CelerumFormInputWithIcon = ({
	validationMessage,
	required,
	touched,
	label,
	id,
	valid,
	disabled,
	hint,
	type,
	optional,
	children,
	...others
}: FieldRenderProps) => {
	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";

	return (
		<FieldWrapper className={styles.inputContainer}>
			<Label
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
				optional={optional}
			>
				{label} {required && <span style={{ color: "red" }}>*</span>}
			</Label>
			<div className={"k-form-field-wrap"}>
				<TextBox
					className={styles.input}
					valid={valid}
					type={type}
					id={id}
					disabled={disabled}
					{...others}
					suffix={() => <InputSuffix>{children}</InputSuffix>}
				/>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && (
					<LabelError id={errorId}>{validationMessage}</LabelError>
				)}
			</div>
		</FieldWrapper>
	);
};

export const CelerumFormTextArea = ({
	validationMessage,
	required,
	touched,
	label,
	id,
	valid,
	hint,
	focused,
	disabled,
	optional,
	...others
}: FieldRenderProps) => {
	const textAreaRef = useRef<TextAreaHandle | null>(null);

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";

	useEffect(() => {
		if (textAreaRef.current && focused === "true") {
			textAreaRef.current.focus();
		}
	}, [focused]);

	return (
		<FieldWrapper className={styles.textAreaContainer}>
			<Label
				style={{
					color: disabled ? "#9D9DAB" : valid ? "#000000" : "",
				}}
				editorId={id}
				editorValid={valid}
				optional={optional}
			>
				{label} {required && <span style={{ color: "red" }}>*</span>}
			</Label>
			<TextArea
				valid={valid}
				id={id}
				disabled={disabled}
				ariaDescribedBy={`${hintId} ${errorId}`}
				{...others}
				rows={1}
				ref={textAreaRef}
				className={styles.textarea}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && (
				<LabelError id={errorId}>{validationMessage}</LabelError>
			)}
		</FieldWrapper>
	);
};

export const CelerumFormDropDownList = ({
	validationMessage,
	required,
	touched,
	label,
	id,
	valid,
	disabled,
	hint,
	textField,
	data,
	...others
}: FieldRenderProps) => {
	// biome-ignore lint/suspicious/noExplicitAny: can't be bothered
	const editorRef = useRef<any>(null);

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";
	const labelId: string = label ? `${id}_label` : "";

	return (
		<FieldWrapper className={styles.inputContainer}>
			<Label
				style={{
					color: disabled ? "#9D9DAB" : valid ? "#000000" : "",
				}}
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label} {required && <span style={{ color: "red" }}>*</span>}
			</Label>
			<DropDownList
				className={styles.input}
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				textField={textField}
				id={id}
				disabled={disabled}
				/** This is needed because DropdownList from KendoReact dosen't provide a prop which could clear the input.
				 * When a dropdown field is not required, the user can clear the input by selecting an empty item (the first one).
				 * If the elements from dropdown are objects, the first element value will look like {name: ""}.
				 * If the elements from dropdown are strings, the first element will be an empty string "".
				 */
				data={
					required || !data.length
						? data
						: [typeof data[0] === "object" ? { [textField]: "" } : "", ...data]
				}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && (
				<LabelError id={errorId}>{validationMessage}</LabelError>
			)}
		</FieldWrapper>
	);
};

export const CelerumFormMultiSelect = ({
	validationMessage,
	required,
	touched,
	label,
	id,
	valid,
	disabled,
	hint,
	...others
}: FieldRenderProps) => {
	// biome-ignore lint/suspicious/noExplicitAny: can't be bothered
	const editorRef = useRef<any>(null);
	const showValidationMessage = touched && validationMessage;
	const showHint = !showValidationMessage && hint;
	const hintId = showHint ? `${id}_hint` : "";
	const errorId = showValidationMessage ? `${id}_error` : "";
	const labelId = label ? `${id}_label` : "";

	return (
		<FieldWrapper className={styles.inputContainer}>
			<Label
				style={{
					color: disabled ? "#9D9DAB" : valid ? "#000000" : "",
				}}
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label} {required && <span style={{ color: "red" }}>*</span>}
			</Label>
			<MultiSelect
				className={cls(styles.input, styles.multiselect)}
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ref={editorRef}
				valid={valid}
				id={id}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && (
				<LabelError id={errorId}>{validationMessage}</LabelError>
			)}
		</FieldWrapper>
	);
};

export const CelerumFormDatePicker = ({
	validationMessage,
	required,
	touched,
	label,
	id,
	valid,
	disabled,
	hint,
	hintDirection,
	...others
}: FieldRenderProps) => {
	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";
	const labelId: string = label ? `${id}_label` : "";

	return (
		<FieldWrapper className={styles.inputContainer}>
			<Label
				id={labelId}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label} {required && <span style={{ color: "red" }}>*</span>}
			</Label>
			<div className={"k-form-field-wrap"}>
				<DatePicker
					className={styles.input}
					ariaLabelledBy={labelId}
					ariaDescribedBy={`${hintId} ${errorId}`}
					valid={valid}
					id={id}
					format="dd/MMMM/yyyy"
					disabled={disabled}
					{...others}
				/>
				{showHint && (
					<Hint id={hintId} direction={hintDirection}>
						{hint}
					</Hint>
				)}
				{showValidationMessage && (
					<LabelError id={errorId}>{validationMessage}</LabelError>
				)}
			</div>
		</FieldWrapper>
	);
};

export const CelerumFormDateTimePicker = ({
	validationMessage,
	required,
	touched,
	label,
	id,
	valid,
	disabled,
	hint,
	value,
	min,
	name,
	onChange,
	...others
}: FieldRenderProps) => {
	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";
	const labelId: string = label ? `${id}_label` : "";

	return (
		<FieldWrapper className={styles.inputContainer}>
			<Label
				style={{
					color: disabled ? "#9D9DAB" : valid ? "#000000" : "",
				}}
				id={labelId}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label} {required && <span style={{ color: "red" }}>*</span>}
			</Label>
			<DateTimePicker
				className={styles.input}
				ariaLabelledBy={labelId}
				ariaDescribedBy={`${hintId} ${errorId}`}
				valid={valid}
				id={id}
				name={name}
				disabled={disabled}
				value={value}
				min={min ?? undefined}
				onChange={onChange}
				{...others}
				format="dd/MM/yyyy HH:mm"
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && (
				<LabelError id={errorId}>{validationMessage}</LabelError>
			)}
		</FieldWrapper>
	);
};

export const CelerumFormRadioGroup = ({
	validationMessage,
	required,
	touched,
	id,
	label,
	valid,
	disabled,
	hint,
	defaultValue,
	...others
}: FieldRenderProps) => {
	// biome-ignore lint/suspicious/noExplicitAny: can't be bothered
	const editorRef = useRef<any>(null);

	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : "";
	const errorId: string = showValidationMessage ? `${id}_error` : "";
	const labelId: string = label ? `${id}_label` : "";

	return (
		<FieldWrapper className={styles.inputContainer}>
			<Label
				style={{
					color: disabled ? "#9D9DAB" : valid ? "#000000" : "",
				}}
				id={labelId}
				editorRef={editorRef}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label} {required && <span style={{ color: "red" }}>*</span>}
			</Label>
			<RadioGroup
				ariaDescribedBy={`${hintId} ${errorId}`}
				ariaLabelledBy={labelId}
				valid={valid}
				disabled={disabled}
				ref={editorRef}
				defaultValue={defaultValue}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && (
				<LabelError id={errorId}>{validationMessage}</LabelError>
			)}
		</FieldWrapper>
	);
};

type CelerumFormUploadInputProps = {
	label: string;
	value: UploadFileInfo[];
	onChange: (event: { value: UploadFileInfo[] }) => void;
};
export const CelerumFormUploadInput = ({
	onChange,
	label,
	value,
}: CelerumFormUploadInputProps) => {
	const [errorVisible, setErrorVisible] = useState(false);

	const onChangeHandler = (event: UploadOnAddEvent) => {
		if (event.newState.length <= 5) {
			onChange({ value: event.newState });
			setErrorVisible(false);
		} else {
			setErrorVisible(true);
		}
	};

	return (
		<>
			<FieldWrapper>
				<Label>{label}</Label>
				<Upload
					autoUpload={false}
					showActionButtons={false}
					files={value}
					onAdd={onChangeHandler}
					onRemove={onChangeHandler}
				/>
			</FieldWrapper>
			{errorVisible && (
				<LabelError>
					You can’t upload more than five documents at once.
				</LabelError>
			)}
		</>
	);
};

interface CelerumSuggestionsInputProps {
	label: string;
	footer: ReactNode;
	data: IBase[];
	loading: boolean;
	value: IBase;
	name: string;
	id: string;
	valid: boolean;
	disabled: boolean;
	required: boolean;
	touched: boolean;
	validationMessage: string;
	defaultValue?: IBase;
	placeholder?: string;
	textField?: string;
	onChange: (event: ComboBoxChangeEvent) => void;
	handleSearchChange: (value: string) => void;
	onFooterClick?: () => void;
}

export const CelerumSuggestionsInput = ({
	id,
	disabled,
	required,
	valid,
	touched,
	name,
	value,
	defaultValue,
	label,
	footer,
	data,
	loading,
	placeholder,
	handleSearchChange,
	onChange,
	textField = "name",
	validationMessage = "",
	onFooterClick,
}: CelerumSuggestionsInputProps) => {
	const showValidationMessage: string | false | null =
		touched && validationMessage;
	const errorId: string = showValidationMessage ? `${id}_error` : "";

	return (
		<FieldWrapper className={styles.inputContainer}>
			<Label
				style={{
					color: disabled ? "#9D9DAB" : valid ? "#000000" : "",
				}}
				editorId={id}
				editorValid={valid}
				editorDisabled={disabled}
			>
				{label} {required && <span style={{ color: "red" }}>*</span>}
			</Label>
			<ComboBox
				name={name}
				className={cls(styles.input, "combobox")}
				dataItemKey="id"
				textField={textField}
				placeholder={placeholder || "Search..."}
				value={value}
				defaultValue={defaultValue}
				data={data || []}
				loading={loading}
				onChange={(event) => onChange(event)}
				onFilterChange={(event) => {
					handleSearchChange(event?.filter?.value || "");
				}}
				filterable={true}
				footer={<div onMouseDown={onFooterClick}>{footer}</div>}
				disabled={disabled}
			/>
			{showValidationMessage && (
				<LabelError id={errorId}>{validationMessage}</LabelError>
			)}
		</FieldWrapper>
	);
};
