import {
	Field,
	Form,
	FormElement,
	type FormRenderProps,
	type FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import cls from "classnames";
import { camelCase } from "es-toolkit";
import {
	type Dispatch,
	type SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { addAttachmentsAction } from "../../../../../../attachments/data-access/src/lib/attachments.slice";
import { BUSINESS_UNITS_FEATURE_KEY } from "../../../../../../common/models/src/lib/constants/business-unit.constants";
import { DRIVERS_FEATURE_KEY } from "../../../../../../common/models/src/lib/constants/driver.constants";
import { SUPPLIER_INVOICES } from "../../../../../../common/models/src/lib/constants/invoice.constants";
import { AttachmentUsage } from "../../../../../../common/models/src/lib/enums/attachment.enum";
import { SupplierInvoiceType } from "../../../../../../common/models/src/lib/enums/invoice.enum";
import { LegType } from "../../../../../../common/models/src/lib/enums/leg.enum";
import { ModalSize } from "../../../../../../common/models/src/lib/enums/modal.enums";
import { ParentType } from "../../../../../../common/models/src/lib/enums/parent-type.enum";
import type { IBase } from "../../../../../../common/models/src/lib/interfaces/base.interface";
import type {
	IBlankLegRequestDto,
	IClearCustomsLeg,
	IClearCustomsLegRequestDto,
	IFerryLeg,
	IFerryLegRequestDto,
	IOperationLeg,
	IOperationLegRequestDto,
	IStorageLeg,
	IStorageLegRequestDto,
} from "../../../../../../common/models/src/lib/interfaces/leg.interface";
import type {
	AnyLeg,
	AnyLegRequestDto,
} from "../../../../../../common/models/src/lib/types/leg.type";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../../common/stores/src/lib/utils";
import { CelerumSubmitButton } from "../../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumFormDropDownList } from "../../../../../../common/ui/src/lib/components/celerum-form-elements/celerum-form-elements.component";
import { toISOStringWithoutTimezoneOffset } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import { useInputChange } from "../../../../../../common/utils/src/lib/hooks/use-input-change.hooks";
import { requiredValidator } from "../../../../../../common/utils/src/lib/validators/validators";
import { fetchJobByIdAction } from "../../../../../../jobs/data-access/src/lib/jobs.slice";
import { fetchLoadByIdAction } from "../../../../../../loads/data-access/src/lib/loads.slice";
import { LocationForm } from "../../../../../../locations/feature/src/lib/components/location-form/location-form.component";
import {
	attachDocumentsToLegAction,
	createLegAction,
	updateLegAction,
} from "../../../../../data-access/src/lib/legs.slice";
import { BlankFields } from "./components/blank-fields/blank-fields.component";
import { ClearCustomsFields } from "./components/clear-customs-fields/clear-customs-fields.component";
import { FerryFields } from "./components/ferry-fields/ferry-fields.component";
import { OperationFields } from "./components/operation-fields/operation-fields.component";
import { StorageFields } from "./components/storage-fields/storage-fields.component";
import styles from "./leg-form.module.css";

interface LegFormProps {
	parentId: string | undefined;
	parentType: ParentType;
	leg?: AnyLeg;
	setModalSize: Dispatch<SetStateAction<ModalSize>>;
	onClose: () => void;
}

type Visibility = {
	operation: boolean;
	storage: boolean;
	ferry: boolean;
	clearCustoms: boolean;
	blank: boolean;
};

type VisibilityMap = {
	[key in
		| "collection"
		| "delivery"
		| "segment"
		| "collectDeliver"
		| "storage"
		| "ferry"
		| "clearCustoms"
		| "blank"
		| "containerPickUp"
		| "containerDropOff"
		| "containerGoodsCollection"
		| "containerGoodsDelivery"
		| "loadAndLash"
		| "devan"
		| "default"]: Visibility;
};

const TYPE_VISIBILITY_MAP: VisibilityMap = {
	collection: {
		operation: true,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: false,
	},
	collectDeliver: {
		operation: true,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: false,
	},
	delivery: {
		operation: true,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: false,
	},
	segment: {
		operation: true,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: false,
	},
	storage: {
		operation: false,
		storage: true,
		ferry: false,
		clearCustoms: false,
		blank: false,
	},
	ferry: {
		operation: false,
		storage: false,
		ferry: true,
		clearCustoms: false,
		blank: false,
	},
	clearCustoms: {
		operation: false,
		storage: false,
		ferry: false,
		clearCustoms: true,
		blank: false,
	},
	blank: {
		operation: false,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: true,
	},
	containerPickUp: {
		operation: true,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: false,
	},
	containerDropOff: {
		operation: true,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: false,
	},
	containerGoodsCollection: {
		operation: true,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: false,
	},
	containerGoodsDelivery: {
		operation: true,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: false,
	},
	loadAndLash: {
		operation: true,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: false,
	},
	devan: {
		operation: true,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: false,
	},
	default: {
		operation: false,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: false,
	},
};

export const LegForm = ({
	leg,
	parentId,
	parentType,
	setModalSize,
	onClose,
}: LegFormProps) => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const handleInputChange = useInputChange();

	const { goods, loading, trucks, availableLegTypes, availableStageTypes } =
		useAppSelector((state) => ({
			goods: state.goods.data,
			loading: state.legs.loading,
			trucks: state.trucks.data,
			availableLegTypes: state.authentication.availableLegTypes,
			availableStageTypes: state.authentication.availableStageTypes,
		}));

	const [goodsIds, setGoodsIds] = useState<number[]>(
		leg?.goods ? leg.goods.map((item: IBase) => item.id) : [],
	);
	const [selectedType, setSelectedType] = useState<{
		id: number;
		name: string;
	} | null>(null);
	const [selectedChauffeur, setSelectedChauffeur] =
		useState<string>(DRIVERS_FEATURE_KEY);
	const [showLocationForm, setShowLocationForm] = useState<boolean>(false);
	const [formData, setFormData] = useState<AnyLeg | undefined>();
	const [fieldToUpdate, setFieldToUpdate] = useState<string>();
	const [isFormModified, setIsFormModified] = useState<boolean>(false);

	const isLegTransferred = useMemo(() => {
		const operationLeg = leg as IOperationLeg;
		return !!(
			operationLeg?.transferBusinessUnitId &&
			!(
				operationLeg?.driver ??
				operationLeg?.trailer ??
				operationLeg?.truck ??
				operationLeg?.subcontractor
			)
		);
	}, [leg]);

	const [visibleFormElements, setVisibleFormElements] = useState({
		operation: false,
		storage: false,
		ferry: false,
		clearCustoms: false,
		blank: false,
	});

	const renderedLeg = useMemo(() => {
		if (leg) {
			switch (leg.type) {
				case LegType.Collection:
				case LegType.Delivery:
				case LegType.CollectDeliver:
				case LegType.Segment:
				case LegType.ContainerPickUp:
				case LegType.ContainerDropOff:
				case LegType.ContainerGoodsCollection:
				case LegType.ContainerGoodsDelivery:
				case LegType.LoadAndLash:
				case LegType.Devan: {
					const truck = trucks.find((item) => item.id === leg.truck?.id);
					return {
						...leg,
						cost: Math.round(leg.cost * 100) / 100,
						collectionDate: leg.collectionDate
							? new Date(leg.collectionDate)
							: null,
						deliveryDate: leg.deliveryDate ? new Date(leg.deliveryDate) : null,
						supplierInvoice:
							leg?.supplierInvoice !== undefined
								? SUPPLIER_INVOICES[leg.supplierInvoice]
								: null,
						supplierInvoiceDate: leg.supplierInvoiceDate
							? new Date(leg.supplierInvoiceDate)
							: null,
						truck: truck
							? {
									id: truck.id,
									name:
										truck.name +
										(truck.subcontractor
											? ` (${truck.subcontractor.name})`
											: ""),
								}
							: leg.truck,
					};
				}
				case LegType.Storage:
					return {
						...leg,
						cost: Math.round(leg.cost * 100) / 100,
						storageStartDate: leg.storageStartDate
							? new Date(leg.storageStartDate)
							: null,
						storageEndDate: leg.storageEndDate
							? new Date(leg.storageEndDate)
							: null,
						supplierInvoice:
							leg?.supplierInvoice !== undefined
								? SUPPLIER_INVOICES[leg.supplierInvoice]
								: null,
						supplierInvoiceDate: leg.supplierInvoiceDate
							? new Date(leg.supplierInvoiceDate)
							: null,
					};
				case LegType.ClearCustoms: {
					const truck = trucks.find((item) => item.id === leg.truck?.id);
					return {
						...leg,
						cost: Math.round(leg.cost * 100) / 100,
						clearanceDate: leg.clearanceDate
							? new Date(leg.clearanceDate)
							: null,
						supplierInvoice:
							leg?.supplierInvoice !== undefined
								? SUPPLIER_INVOICES[leg.supplierInvoice]
								: null,
						supplierInvoiceDate: leg.supplierInvoiceDate
							? new Date(leg.supplierInvoiceDate)
							: null,
						truck: truck
							? {
									id: truck.id,
									name:
										truck.name +
										(truck.subcontractor
											? ` (${truck.subcontractor.name})`
											: ""),
								}
							: leg.truck,
					};
				}
				case LegType.Ferry: {
					const truck = trucks.find((item) => item.id === leg.truck?.id);
					return {
						...leg,
						cost: Math.round(leg.cost * 100) / 100,
						ferrySailingDate: leg.ferrySailingDate
							? new Date(leg.ferrySailingDate)
							: null,
						supplierInvoice:
							leg?.supplierInvoice !== undefined
								? SUPPLIER_INVOICES[leg.supplierInvoice]
								: null,
						supplierInvoiceDate: leg.supplierInvoiceDate
							? new Date(leg.supplierInvoiceDate)
							: null,
						truck: truck
							? {
									id: truck.id,
									name:
										truck.name +
										(truck.subcontractor
											? ` (${truck.subcontractor.name})`
											: ""),
								}
							: leg.truck,
					};
				}
				default:
					return {
						...leg,
						cost: Math.round(leg.cost * 100) / 100,
					};
			}
		}
	}, [leg, trucks]) as AnyLeg;

	const renderedGoods = useMemo(
		() =>
			goods?.map((item) => ({
				id: item.id,
				name: item.name,
				collectionDate: item.collectionDate
					? new Date(item.collectionDate)
					: null,
				collectionLocation: item.collectionLocation ?? null,
				deliveryDate: item.deliveryDate ? new Date(item.deliveryDate) : null,
				deliveryLocation: item.deliveryLocation ?? null,
			})),
		[goods],
	);

	const getInitialValues = useCallback(() => {
		if (renderedLeg) {
			return renderedLeg;
		}
		if (formData && Object.keys(formData).length !== 0) {
			return formData;
		}
	}, [renderedLeg, formData]);

	const isDisabled = (formRenderProps: FormRenderProps) => {
		return (
			(!isFormModified && !formRenderProps.allowSubmit) ||
			Number.parseFloat(formRenderProps.valueGetter("cost")) < 0 ||
			loading
		);
	};

	const handleSubmit = (event: FormSubmitClickEvent) => {
		const {
			legType,
			cost,
			currency,
			notes,
			files,
			supplierInvoice,
			supplierInvoiceNumber,
			supplierInvoiceDate,
		} = event.values;

		if (!event.isValid) {
			return;
		}

		const formDocuments = new FormData();
		if (files?.length) {
			for (const file of files) {
				formDocuments.append("files", file.getRawFile?.() || new Blob());
			}
		}

		const legName = legType
			? camelCase(legType.name)
			: selectedType
				? camelCase(selectedType.name)
				: "default";

		const requestObject = {
			cost: cost ? Number.parseFloat(cost) : undefined,
			currencyId: currency?.id,
			notes: notes ?? null,
			supplierInvoice: supplierInvoice?.id ?? SupplierInvoiceType.NoInvoice,
			supplierInvoiceNumber: supplierInvoiceNumber,
			supplierInvoiceDate: supplierInvoiceDate
				? toISOStringWithoutTimezoneOffset(supplierInvoiceDate)
				: null,
			goodsIds,
		};

		let operationRequestObject: IOperationLegRequestDto | null = null;
		let storageRequestObject: IStorageLegRequestDto | null = null;
		let ferryRequestObject: IFerryLegRequestDto | null = null;
		let clearCustomsRequestObject: IClearCustomsLegRequestDto | null = null;

		if (visibleFormElements.operation) {
			const {
				truckType,
				trailerType,
				truck,
				trailer,
				driver,
				subcontractor,
				businessUnit,
				collectionLocation,
				collectionDate,
				deliveryLocation,
				deliveryDate,
			} = event.values;

			const operation = {
				collectionLocationId: collectionLocation?.id ?? null,
				collectionDate: collectionDate
					? toISOStringWithoutTimezoneOffset(collectionDate)
					: null,
				deliveryLocationId: deliveryLocation?.id ?? null,
				deliveryDate: deliveryDate
					? toISOStringWithoutTimezoneOffset(deliveryDate)
					: null,
				truckTypeId: truckType?.id ?? null,
				truckId: truck?.id ?? null,
				trailerTypeId: trailerType?.id ?? null,
				trailerId: trailer?.id ?? null,
				driverId: selectedChauffeur === DRIVERS_FEATURE_KEY ? driver?.id : null,
				subcontractorId: subcontractor?.id ?? null,
				transferBusinessUnitId:
					selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY
						? businessUnit?.id
						: null,
			};

			operationRequestObject = {
				...requestObject,
				[legName]: operation,
			} as IOperationLegRequestDto;

			leg
				? handleUpdate({ ...operationRequestObject, id: leg.id })
				: handleCreate(
						operationRequestObject,
						files?.length ? formDocuments : undefined,
					);
		} else if (visibleFormElements.storage) {
			const { storageLocation, storageStartDate, storageEndDate } =
				event.values;

			const storage = {
				storageLocation: storageLocation ?? undefined,
				storageStartDate: storageStartDate
					? toISOStringWithoutTimezoneOffset(storageStartDate)
					: undefined,
				storageEndDate: storageEndDate
					? toISOStringWithoutTimezoneOffset(storageEndDate)
					: undefined,
			};

			storageRequestObject = {
				...requestObject,
				[legName]: storage,
			} as IStorageLegRequestDto;

			leg
				? handleUpdate({ ...storageRequestObject, id: leg.id })
				: handleCreate(
						storageRequestObject,
						files?.length ? formDocuments : undefined,
					);
		} else if (visibleFormElements.clearCustoms) {
			const {
				truckType,
				trailerType,
				truck,
				trailer,
				driver,
				subcontractor,
				businessUnit,
				ucr,
				mrn,
				t1,
				clearanceLocation,
				clearanceDate,
			} = event.values;

			const clearCustoms = {
				ucr: ucr ?? null,
				mrn: mrn ?? null,
				t1: t1 ?? null,
				clearanceLocation: clearanceLocation ?? null,
				clearanceDate: clearanceDate
					? toISOStringWithoutTimezoneOffset(clearanceDate)
					: null,
				truckTypeId: truckType?.id ?? null,
				truckId: truck?.id ?? null,
				trailerTypeId: trailerType?.id ?? null,
				trailerId: trailer?.id ?? null,
				driverId: selectedChauffeur === DRIVERS_FEATURE_KEY ? driver?.id : null,
				subcontractorId: subcontractor?.id ?? null,
				transferBusinessUnitId:
					selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY
						? businessUnit?.id
						: null,
			};

			clearCustomsRequestObject = {
				...requestObject,
				[legName]: clearCustoms,
			} as IClearCustomsLegRequestDto;

			leg
				? handleUpdate({ ...clearCustomsRequestObject, id: leg.id })
				: handleCreate(
						clearCustomsRequestObject,
						files?.length ? formDocuments : undefined,
					);
		} else if (visibleFormElements.ferry) {
			const {
				truckType,
				trailerType,
				truck,
				trailer,
				driver,
				subcontractor,
				businessUnit,
				ferryRoute,
				ferrySailingDate,
				ferryReferenceNumber,
			} = event.values;

			const ferry = {
				ferryRoute: ferryRoute ?? null,
				ferrySailingDate: ferrySailingDate
					? toISOStringWithoutTimezoneOffset(ferrySailingDate)
					: null,
				ferryReferenceNumber: ferryReferenceNumber ?? null,
				truckTypeId: truckType?.id ?? null,
				truckId: truck?.id ?? null,
				trailerTypeId: trailerType?.id ?? null,
				trailerId: trailer?.id ?? null,
				driverId: selectedChauffeur === DRIVERS_FEATURE_KEY ? driver?.id : null,
				subcontractorId: subcontractor?.id ?? null,
				transferBusinessUnitId:
					selectedChauffeur === BUSINESS_UNITS_FEATURE_KEY
						? businessUnit?.id
						: null,
			};

			ferryRequestObject = {
				...requestObject,
				[legName]: ferry,
			} as IFerryLegRequestDto;

			leg
				? handleUpdate({ ...ferryRequestObject, id: leg.id })
				: handleCreate(
						ferryRequestObject,
						files?.length ? formDocuments : undefined,
					);
		} else if (visibleFormElements.blank) {
			const blankRequestObject = {
				...requestObject,
				[legName]: {},
			} as IBlankLegRequestDto;

			leg
				? handleUpdate({ ...blankRequestObject, id: leg.id })
				: handleCreate(
						blankRequestObject,
						files?.length ? formDocuments : undefined,
					);
		}
	};

	const handleCreate = async (
		leg: AnyLegRequestDto,
		formData: FormData | undefined,
	) => {
		if (parentId && !Number.isNaN(Number.parseInt(parentId))) {
			try {
				if (parentType === ParentType.Job) {
					leg.jobId = Number.parseInt(parentId);
				} else {
					leg.loadId = Number.parseInt(parentId);
				}
				const actionResult = await dispatchWithNotifications({
					action: createLegAction,
					payload: leg,
					successMessage: "Leg successfully created.",
					errorMessage: "Could not create leg.",
				});

				if (createLegAction.fulfilled.match(actionResult)) {
					if (formData !== undefined) {
						const actionResultAttachments = await dispatch(
							addAttachmentsAction({
								documents: formData,
								entity: AttachmentUsage.Leg,
								entityId: actionResult.payload.id,
							}),
						);

						if (addAttachmentsAction.fulfilled.match(actionResultAttachments)) {
							dispatch(
								attachDocumentsToLegAction({
									documents: actionResultAttachments.payload,
									legId: actionResult.payload.id,
								}),
							);
						}
					}

					dispatchWithNotifications({
						action:
							parentType === ParentType.Job
								? fetchJobByIdAction
								: fetchLoadByIdAction,
						payload: Number(parentId),
						errorMessage:
							parentType === ParentType.Job
								? "Could not fetch job."
								: "Could not fetch load.",
					});
					onClose();
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	const handleUpdate = async (leg: AnyLegRequestDto) => {
		if (parentId && !Number.isNaN(Number.parseInt(parentId))) {
			try {
				if (parentType === ParentType.Job) {
					leg.jobId = Number.parseInt(parentId);
				} else {
					leg.loadId = Number.parseInt(parentId);
				}
				const actionResult = await dispatchWithNotifications({
					action: updateLegAction,
					payload: leg,
					successMessage: "Leg successfully updated.",
					errorMessage: "Could not update leg.",
				});

				if (updateLegAction.fulfilled.match(actionResult)) {
					dispatchWithNotifications({
						action:
							parentType === ParentType.Job
								? fetchJobByIdAction
								: fetchLoadByIdAction,
						payload: Number(parentId),
						errorMessage:
							parentType === ParentType.Job
								? "Could not fetch job."
								: "Could not fetch load.",
					});
					onClose();
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	useEffect(() => {
		const selectedName = camelCase(selectedType?.name || "default");
		setVisibleFormElements(
			TYPE_VISIBILITY_MAP[selectedName as keyof VisibilityMap],
		);
		if (!leg) {
			setGoodsIds([]);
		}
	}, [leg, selectedType]);

	useEffect(() => {
		visibleFormElements.operation ||
		visibleFormElements.ferry ||
		visibleFormElements.clearCustoms
			? setModalSize(ModalSize.ExtraLarge)
			: setModalSize(ModalSize.Large);
		showLocationForm && setModalSize(ModalSize.Medium);
	}, [visibleFormElements, setModalSize, showLocationForm]);

	useEffect(() => {
		if (leg) {
			const legType = availableLegTypes.find((item) => item.id === leg.type);

			legType && setSelectedType(legType);

			switch (leg.type) {
				case LegType.Collection:
				case LegType.Delivery:
				case LegType.CollectDeliver:
				case LegType.Segment:
				case LegType.ContainerPickUp:
				case LegType.ContainerDropOff:
				case LegType.ContainerGoodsCollection:
				case LegType.ContainerGoodsDelivery:
				case LegType.LoadAndLash:
				case LegType.Devan:
				case LegType.Ferry:
				case LegType.ClearCustoms:
					if (
						leg.driver ||
						leg.trailer ||
						leg.truck ||
						leg.subcontractor ||
						!leg.transferBusinessUnitId
					) {
						setSelectedChauffeur(DRIVERS_FEATURE_KEY);
					} else if (leg.transferBusinessUnitId) {
						setSelectedChauffeur(BUSINESS_UNITS_FEATURE_KEY);
					}
					break;
				default:
					break;
			}
		}
	}, [leg, availableLegTypes]);

	useEffect(() => {
		if (renderedLeg) {
			setFormData(renderedLeg);
		} else {
			setFormData({} as AnyLeg);
		}
	}, [renderedLeg]);

	const renderLegForm = () => {
		return (
			<Form
				initialValues={getInitialValues()}
				onSubmitClick={handleSubmit}
				render={(formRenderProps) => (
					<FormElement>
						<fieldset className="k-form-fieldset">
							{!leg && (
								<Field
									id="legType"
									dataItemKey="id"
									label={
										parentType === ParentType.Job ? "Leg Type" : "Stage Type"
									}
									textField="name"
									name="legType"
									component={CelerumFormDropDownList}
									data={
										parentType === ParentType.Job
											? availableLegTypes
											: availableStageTypes
									}
									validator={requiredValidator}
									required
									defaultValue={selectedType}
									onChange={(event) => {
										setSelectedType(event.target.value);
										handleInputChange(event, "legType", setFormData);
									}}
								/>
							)}
							<div
								className={cls(
									styles.form,
									visibleFormElements.operation ||
										visibleFormElements.ferry ||
										visibleFormElements.clearCustoms
										? styles.form__three
										: styles.form__two,
								)}
							>
								{visibleFormElements.operation && (
									<OperationFields
										formData={formData as IOperationLeg | undefined}
										goods={renderedGoods}
										formRenderProps={formRenderProps}
										selectedType={selectedType}
										selectedChauffeur={selectedChauffeur}
										parentType={parentType}
										updateMode={!!renderedLeg}
										isLegTransferred={isLegTransferred}
										setGoodsIds={setGoodsIds}
										setSelectedChauffeur={setSelectedChauffeur}
										setShowLocationForm={setShowLocationForm}
										setFormData={
											setFormData as Dispatch<
												SetStateAction<IOperationLeg | undefined>
											>
										}
										setFieldToUpdate={setFieldToUpdate}
									/>
								)}
								{visibleFormElements.storage && (
									<StorageFields
										goods={renderedGoods}
										setGoodsIds={setGoodsIds}
										updateMode={!!renderedLeg}
										setFormData={
											setFormData as Dispatch<
												SetStateAction<IStorageLeg | undefined>
											>
										}
									/>
								)}
								{visibleFormElements.clearCustoms && (
									<ClearCustomsFields
										formData={formData as IClearCustomsLeg | undefined}
										goods={renderedGoods}
										formRenderProps={formRenderProps}
										selectedChauffeur={selectedChauffeur}
										parentType={parentType}
										updateMode={!!renderedLeg}
										setGoodsIds={setGoodsIds}
										setSelectedChauffeur={setSelectedChauffeur}
										setFormData={
											setFormData as Dispatch<
												SetStateAction<IClearCustomsLeg | undefined>
											>
										}
										setFieldToUpdate={setFieldToUpdate}
									/>
								)}
								{visibleFormElements.ferry && (
									<FerryFields
										formData={formData as IFerryLeg | undefined}
										goods={renderedGoods}
										formRenderProps={formRenderProps}
										selectedChauffeur={selectedChauffeur}
										parentType={parentType}
										updateMode={!!renderedLeg}
										setGoodsIds={setGoodsIds}
										setSelectedChauffeur={setSelectedChauffeur}
										setFormData={
											setFormData as Dispatch<
												SetStateAction<IFerryLeg | undefined>
											>
										}
										setFieldToUpdate={setFieldToUpdate}
									/>
								)}
								{visibleFormElements.blank && (
									<BlankFields
										goods={renderedGoods}
										setGoodsIds={setGoodsIds}
										updateMode={!!renderedLeg}
									/>
								)}
							</div>
						</fieldset>
						<CelerumSubmitButton
							type="submit"
							disabled={isDisabled(formRenderProps)}
							title="Submit"
						/>
					</FormElement>
				)}
			/>
		);
	};

	const renderLocationForm = () => (
		<LocationForm<AnyLeg>
			addLocationFromAnotherForm
			onClose={() => setShowLocationForm(false)}
			fieldToSet={fieldToUpdate}
			setFormData={setFormData}
			setIsParentFormModified={setIsFormModified}
		/>
	);

	return !showLocationForm ? renderLegForm() : renderLocationForm();
};
